import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams } from "react-router-dom";
import styles from './ElectionView.module.scss';
import mvStyles from './MembersView.module.scss';
import Controller from './Controller';
import { Asset } from '@wharfkit/antelope';
import { ReactSortable } from "react-sortablejs";
import { Sortable, Swap } from "sortablejs";
import micUnmuted from './mic_unmuted.png'
import camPublished from './cam_published.png'
import DateTimePicker from 'react-datetime-picker';
import './datetimepicker.css'

function bn2ts(bn, ref_bn, ref_ts) { return ref_ts + (bn - ref_bn) * 500; }
function ts2bn(ts, ref_bn, ref_ts) { return ref_bn + (ts - ref_ts) / 500; }
function bnDate(bn, rbn, rts)
{
    let d = new Date(bn2ts(bn, rbn, rts))
    let months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
    let year = d.getFullYear();
    let month = months[d.getMonth()];
    let day = d.getDate();
    return month + ' ' + day + ', ' + ' ' + year;
}
function bnTime(bn, rbn, rts)
{
    let d = new Date(bn2ts(bn, rbn, rts))
    let hour = d.getHours();
    let min = d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes();
    let sec = d.getSeconds() < 10 ? '0' + d.getSeconds() : d.getSeconds();
    return hour + ':' + min + ':' + sec;
}
function blocksLeft(bn, state)
{
    switch(state.cur)
    {
        case 0: return state.nebh - state.pd - bn;
        case 1: return state.nebh - bn;
        case 2: return state.nebh + state.rd - bn;
    }
}
function timeLeft(blocks)
{
    if(blocks <= 0) return "00:00:00";
    let hour = blocks / 7200;
    let min  = blocks % 7200 / 120;
    let sec  = blocks % 7200 % 120 / 2;
    return (hour < 10 ? '0' + parseInt(hour) : parseInt(hour)) + ':' + (min < 10 ? '0' + parseInt(min) : parseInt(min)) + ':' + (sec < 10 ? '0' + parseInt(sec) : parseInt(sec));
}

Sortable.mount(new Swap());

function BlockCounter({ session, state })
{
    const [bn, setBN] = useState(0);
    useEffect(() => {
        Controller.setOnBlockNumCallback(setBN);
    }, [])

    return (
        <div className={styles['row']}>
            <b>Time left: </b><div className={styles['countdown']} style={blocksLeft(bn, state) < 120 ? {color: 'red'} : {}}>{timeLeft(blocksLeft(bn, state))}</div>
            {session ? <button disabled={blocksLeft(bn, state) > 3} onClick={Controller.changeState}>
                {
                    {
                        0: 'Start Election',
                        1: 'Breakout Rooms',
                        2: 'End Election'
                    }[state.cur]
                }
            </button> : <></>}
        </div>
    )
}

function Idle({ session, state, isLastElectionArchived, council, members, claims, rewards })
{
    const [datetime, setDatetime] = useState(new Date());

    return (
        <div className='col'>
            <div className={styles['panel']}>
                <div class={styles["panel-heading"]}>
                    <div class={styles["panel-title"]}>Current Council</div>
                </div>
                <div className={styles['panel-body']}>
                    <div className={mvStyles['members-view']}>
                        {council.length === 0 ? <div>No council elected yet.</div> : council.map(m =>
                            <Link to={'/members/' + m}>
                                <div className={mvStyles['panel']}>
                                    <div className={mvStyles['panel-heading']}>
                                        <div>{m}</div>
                                        <div>{(members[m].recent_respect.reduce((Sum, a) => Sum + a, 0) / members[m].recent_respect.length).toFixed(2)}/{members[m].total_respect}</div>
                                    </div>
                                    <div className={mvStyles['panel-body']}>
                                        <img src={members[m].b64_picture} />
                                    </div>
                                </div>
                            </Link>
                        )}
                    </div>
                </div>
            </div>
            {rewards.filter(a => Asset.fromString(a.quantity.quantity).units > 10).length > 0 ?
                <div className={styles['panel']}>
                    <div class={styles["panel-heading"]}>
                        <div class={styles["panel-title"]}>Upcoming Election Rewards</div>
                    </div>
                    <div className={styles['panel-body']}>
                        <div className='col'>
                            {rewards.filter(a => Asset.fromString(a.quantity.quantity).units > 10).map(asset => <div>{asset.quantity.quantity + '@' + asset.quantity.contract}</div>)}
                        </div>
                    </div>
                </div> : <></>
            }
            <div className={styles['row-sb']}>
                <h2>Upcoming Election</h2>
                <BlockCounter session={session} state={state} />
            </div>
            {state.nebh == 0 ?
                <div>Currently no election scheduled.</div>
            :
                <>
                    <div>The next election is scheduled for <b>{bnDate(state.nebh, state.rbn, state.rts)}</b> at <b>{bnTime(state.nebh, state.rbn, state.rts)}</b> your time or {(new Date(bn2ts(state.nebh, state.rbn, state.rts))).toUTCString()} (UTC). The ability to join opens at <b>{bnTime(state.nebh - state.pd, state.rbn, state.rts)}</b>, {state.pd/2/60} minutes before the election starts. When the timer reaches 0:00 anyone can initiate the election by triggering the <b>'Start Election'</b> action.</div>
                    {session ?
                        <div className={styles['row-left']}>
                            <div>Propose a new date & time for the next upcoming election (UTC):</div>
                            <DateTimePicker onChange={setDatetime} value={datetime} maxDetail="second" calendarIcon={null} />
                            <button onClick={() => Controller.proposeNewElectionBlockHeight(new Date(datetime.getTime() - datetime.getTimezoneOffset() * 60000).toISOString())}>Propose</button>
                        </div> : <></>
                    }
                </>
            }
            {session && !isLastElectionArchived ?
                <div className={styles['panel']}>
                    <div className={styles['panel-body']}>
                        <div className={styles['row-sb']}>
                            <b>The previous Election is not yet archived. Please click 'Archive Election' in order to push the latest election event on chain.</b>
                            <button onClick={Controller.archiveElection}>Archive Election</button>
                        </div>
                    </div>
                </div> : <></>
            }
            {session && claims.length > 0 ?
                <div className={styles['panel']}>
                    <div class={styles["panel-heading"]}>
                        <div class={styles["panel-title"]}>Unclaimed Election Rewards</div>
                    </div>
                    <div className={styles['panel-body']}>
                        <div className='col'>
                            {claims.map(asset => <div>{asset.quantity.quantity + '@' + asset.quantity.contract}</div>)}
                            <button onClick={Controller.claimRewards}>Claim</button>
                        </div>
                    </div>
                </div> : <></>
            }
        </div>
    )
}

function Participate({ session, participants, state, council, members, rewards })
{
    return (
        <div className='col'>
            <div className={styles['panel']}>
                <div class={styles["panel-heading"]}>
                    <div class={styles["panel-title"]}>Current Council</div>
                </div>
                <div className={styles['panel-body']}>
                    <div className={mvStyles['members-view']}>
                        {council.length === 0 ? <div>No elected Council yet.</div> : council.map(m =>
                            <Link to={'/members/' + m}>
                                <div className={mvStyles['panel']}>
                                    <div className={mvStyles['panel-heading']}>
                                        <div>{m}</div>
                                        <div>{(members[m].recent_respect.reduce((Sum, a) => Sum + a, 0) / members[m].recent_respect.length).toFixed(2)}/{members[m].total_respect}</div>
                                    </div>
                                    <div className={mvStyles['panel-body']}>
                                        <img src={members[m].b64_picture} />
                                    </div>
                                </div>
                            </Link>
                        )}
                    </div>
                </div>
            </div>
            {rewards.filter(a => Asset.fromString(a.quantity.quantity).units > 10).length > 0 ?
                <div className={styles['panel']}>
                    <div class={styles["panel-heading"]}>
                        <div class={styles["panel-title"]}>Upcoming Election Rewards</div>
                    </div>
                    <div className={styles['panel-body']}>
                        <div className='col'>
                            {rewards.filter(a => Asset.fromString(a.quantity.quantity).units > 10).map(asset => asset.quantity.quantity + '@' + asset.quantity.contract)}
                        </div>
                    </div>
                </div> : <></>
            }
            <div className={styles['row-sb']}>
                <h2>Current Election</h2>
                <BlockCounter session={session} state={state} />
            </div>
            <div>Participants have <b>{state.pd/2/60} minutes</b> to execute the <b>'Join Election'</b> action. Your image will appear alongside others in the Election Participants list below once processed. When the timer reaches 0:00 anyone can trigger the <b>'Breakout Rooms'</b> action. While you wait you can help to <b>'Randomize'</b> the breakout rooms with that action.</div>
            {session ? <h4><button onClick={Controller.joinEvent}>Join Election</button> <button onClick={Controller.randomize}>Randomize</button></h4> : <></>}
            <div className={styles['panel']}>
                <div class={styles["panel-heading"]}>
                    <div class={styles["panel-title"]}>Election Participants</div>
                </div>
                <div className={styles['panel-body']}>
                    <div className={mvStyles['members-view']}>
                        {participants.length === 0 ? <div>No participants yet.</div> : participants.map(m =>
                            <Link to={'/members/' + m}>
                                <div className={mvStyles['panel']}>
                                    <div className={mvStyles['panel-heading']}>
                                        <div>{m}</div>
                                        <div>{(members[m].recent_respect.reduce((Sum, a) => Sum + a, 0) / members[m].recent_respect.length).toFixed(2)}/{members[m].total_respect}</div>
                                    </div>
                                    <div className={mvStyles['panel-body']}>
                                        <img src={members[m].b64_picture} />
                                    </div>
                                </div>
                            </Link>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

function Rooms({ session, rooms, myRoom, authToken, state, members, joinedRoom })
{
    const [ranking, setRanking] = useState([]);
    const [emojis, setEmojis] = useState([]);
    useEffect(() => {
        Controller.setOnRankingCallback(setRanking);
        Controller.setOnEmojisCallback(setEmojis);
    }, [])

    return (
        <div>
            <div className={styles['row-sb']}>
                <h2>Current Election</h2>
                <BlockCounter session={session} state={state} />
            </div>
            {!joinedRoom ?
                <div>
                    <div>The breakout rooms have been created. Join your randomly assigned room by executing the <b>'Authenticate (Room#)'</b> action. Once completed join via the <b>'Enter Room'</b> action. The Rooms are going to be closed at approximately <b>{bnTime(state.nebh + state.rd, state.rbn, state.rts)}</b>.</div>
                    <div className={styles['row-sb']}>
                        <h4>Breakout Rooms</h4>
                        {!session || myRoom === 0 ? <></> : authToken === "" ? 
                            <button onClick={Controller.authenticate}>Authenticate (Room {myRoom})</button> :
                            <button onClick={() => Controller.joinRoom(authToken)}>Enter Room {myRoom}</button>
                        }
                    </div>
                    <div className='col'>
                        {rooms.map(r =>
                            <div className={styles['panel']}>
                                <div class={styles["panel-heading"]}>
                                    <div class={styles["panel-title"]}>Room {r.id}</div>
                                </div>
                                <div className={styles['panel-body']}>
                                    <div className={mvStyles['members-view']}>
                                        {r.users.map(u => 
                                            <Link to={'/members/' + u}>
                                                <div className={mvStyles['panel']}>
                                                    <div className={mvStyles['panel-heading']}>
                                                        <div>{u}</div>
                                                        <div>{(members[u].recent_respect.reduce((Sum, a) => Sum + a, 0) / members[u].recent_respect.length).toFixed(2)}/{members[u].total_respect}</div>
                                                    </div>
                                                    <div className={mvStyles['panel-body']}>
                                                        <img src={members[u].b64_picture} />
                                                    </div>
                                                </div>
                                            </Link>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            :
                <div className='col'>
                    <div>Welcome to <b>Breakout Room {myRoom}, this room is being recorded at all times.</b> You control your mute and camera icons. Individual time spoken is recorded on the blue timer below your image. If you're having issues <b>'Exit Room'</b> and then <b>'Enter Room'</b> may resolve these. You have only one <b>'Submit'</b> vote action and must enter a rank order before the election ends at approximately <b>{bnTime(state.nebh + state.rd, state.rbn, state.rts)}</b> and the <b>'End Election'</b> action is executed by any member. Drag and drop participants to change their order, with the top being the highest rank. <b>Token rewards are only issued if full consensus is discovered.</b></div>
                    {/* This is the place where you present your most recent contributions to the other room participants. Keep in mind that everybody needs some time to get to speak. Below the video grid you rank each others contributions. Whoever you think contributed the most should be ranked at the top. The least valuable contribution goes at the bottom. The numbers next to the boxes indicate the consensus about each rank. After you reached consensus about the ranks click on 'Submit Ranks'. <b>This must happen before the timer above reaches zero.</b> The Breakout Rooms are going to be closed at approximately <b>{bnTime(state.nebh + state.rd, state.rbn, state.rts)}</b>. That's it! Enjoy the conversation and keep in mind: Be nice and respectful. This is about reaching consensus. */}
                    <div  className={styles['row-sb']}>
                        <b>&#x1F534; This Room is being recorded!</b>
                        <button onClick={Controller.exitRoom}>Exit Room</button>
                    </div>
                    <div className={styles['column']}>
                        <div id={styles["video-grid"]}>
                                <div className={styles['video-panel']}>
                                    <div class={styles["panel"]}>
                                        <div class={styles["panel-heading"]}>
                                            <div class={styles["panel-title"]}>You<span id="publisher"></span>
                                                <div className={styles['row-ng']}>
                                                    <img src={micUnmuted} id='mute' className={styles['mic-icon']} onClick={Controller.toggleMute} />
                                                    <img src={camPublished} id='unpublish' className={styles['cam-icon']} onClick={Controller.togglePublish} />
                                                    <select id="bitrate" onChange={Controller.setBitrate}>
                                                        <option value="0">No limit</option>
                                                        <option value="128">Cap to 128kbit</option>
                                                        <option value="256">Cap to 256kbit</option>
                                                        <option value="512">Cap to 512kbit</option>
                                                        <option value="1024">Cap to 1mbit</option>
                                                        <option value="1500">Cap to 1.5mbit</option>
                                                        <option value="2000">Cap to 2mbit</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class={styles["panel-body"]} id="videolocal"></div>
                                        <div class={styles["panel-footing"]}>
                                            <div id="clocklocal" class={styles["clock"]}>00:00:00</div>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles['video-panel']}>
                                    <div class={styles["panel"]}>
                                        <div class={styles["panel-heading"]}>
                                            <div class={styles["panel-title"]}>
                                                <span id="remote1"></span>
                                                <div><span id='curres1'></span><span id='curbitrate1'></span></div>
                                            </div>
                                        </div>
                                        <div class={styles["panel-body"]} id="videoremote1"></div>
                                        <div class={styles["panel-footing"]}>
                                            <div id="clockremote1" class={styles["clock"]}>00:00:00</div>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles['video-panel']}>
                                    <div class={styles["panel"]}>
                                        <div class={styles["panel-heading"]}>
                                            <div class={styles["panel-title"]}>
                                                <span id="remote2"></span>
                                                <div><span id='curres2'></span><span id='curbitrate2'></span></div>
                                            </div>
                                        </div>
                                        <div class={styles["panel-body"]} id="videoremote2"></div>
                                        <div class={styles["panel-footing"]}>
                                            <div id="clockremote2" class={styles["clock"]}>00:00:00</div>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles['video-panel']}>
                                    <div class={styles["panel"]}>
                                        <div class={styles["panel-heading"]}>
                                            <div class={styles["panel-title"]}>
                                                <span id="remote3"></span>
                                                <div><span id='curres3'></span><span id='curbitrate3'></span></div>
                                            </div>
                                        </div>
                                        <div class={styles["panel-body"]} id="videoremote3"></div>
                                        <div class={styles["panel-footing"]}>
                                            <div id="clockremote3" class={styles["clock"]}>00:00:00</div>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles['video-panel']}>
                                    <div class={styles["panel"]}>
                                        <div class={styles["panel-heading"]}>
                                            <div class={styles["panel-title"]}>
                                                <span id="remote4"></span>
                                                <div><span id='curres4'></span><span id='curbitrate4'></span></div>
                                            </div>
                                        </div>
                                        <div class={styles["panel-body"]} id="videoremote4"></div>
                                        <div class={styles["panel-footing"]}>
                                            <div id="clockremote4" class={styles["clock"]}>00:00:00</div>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles['video-panel']}>
                                    <div class={styles["panel"]}>
                                        <div class={styles["panel-heading"]}>
                                            <div class={styles["panel-title"]}>
                                                <span id="remote5"></span>
                                                <div><span id='curres5'></span><span id='curbitrate5'></span></div>
                                            </div>
                                        </div>
                                        <div class={styles["panel-body"]} id="videoremote5"></div>
                                        <div class={styles["panel-footing"]}>
                                            <div id="clockremote5" class={styles["clock"]}>00:00:00</div>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                    <ReactSortable
                        swap
                        list={ranking}
                        setList={() => {}}
                        onUpdate={(ev) => Controller.onDropRank(ev.oldIndex, ev.newIndex, authToken)}
                        className={styles["rank-list"]}
                    >
                        {ranking.map((rank, index) => (
                            <div className={styles["rank"]}>
                                <div>{emojis[index]}{'(' + Controller.respectAmount(6-1 - index) + ')'}</div>
                                <div className={styles['row']}>
                                    <img src={rank.content.b64_picture} />
                                    <b>{rank.id}</b>
                                </div>
                                <div>
                                    {rank.content.avg}/{rank.content.ttl}
                                </div>
                            </div>
                        ))}
                    </ReactSortable>
                    <div className={styles['column']}>
                        <button onClick={Controller.submitRanks}>Submit Ranks</button>
                    </div>
                    <div className={styles['panel']}>
                        <div class={styles["panel-heading"]}>
                            <div class={styles["panel-title"]}>Consensus Legend</div>
                        </div>
                        <div className={styles['panel-body']}>
                            <div className={styles['row-sb']}>
                                <div>&#x1F7E2; Unanimous Consensus</div>
                                <div>&#x1F7E1; Consensus (and you are part of it)</div>
                                <div>&#x1F7E0; Consensus (but you are <i>not</i> part of it)</div>
                                <div>&#x1F534; No Consensus</div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

function ElectionView({ session, participants, rooms, myRoom, authToken, joinedRoom, state, isLastElectionArchived, council, members, claims, rewards })
{
    return (
        <div className={styles['column']}>
            <div className={styles['row']}>
                <div className='container'>
                    <div className={styles['column']}>
                        {
                            {
                                0: <Idle session={session} state={state} isLastElectionArchived={isLastElectionArchived} council={council} members={members} claims={claims} rewards={rewards} />,
                                1: <Participate session={session} participants={participants} state={state} council={council} members={members} rewards={rewards} />,
                                2: <Rooms session={session} rooms={rooms} myRoom={myRoom} authToken={authToken} joinedRoom={joinedRoom} state={state} members={members} />
                            }[state.cur]
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ElectionView;