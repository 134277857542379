import React, { useState, useEffect, useRef } from 'react';
import styles from './SignupView.module.scss';
import Controller from './Controller';
import profilePlaceholer from './profile_placeholder.jpg';

function previewImage()
{
    const [file] = document.getElementById('profile-picture-file').files
    if(file)
    {
        var reader = new FileReader();
        reader.onload = function (e) {
            var img = document.createElement('img');
            img.onload = function (event) {
                var canvas = document.createElement('canvas'),
                    ctx = canvas.getContext("2d"),
                    oc = document.createElement('canvas'),
                    octx = oc.getContext('2d');

                let width = 256;
                canvas.width = width;   // destination canvas size
                canvas.height = width;  // squared image
            
                var cur = {
                    width: Math.floor(img.width * 0.5),
                    height: Math.floor(img.height * 0.5)
                }

                oc.width = cur.width;
                oc.height = cur.height;
            
                octx.drawImage(img, 0, 0, cur.width, cur.height);

                while(cur.width * 0.5 > width)
                {
                    cur = {
                        width: Math.floor(cur.width * 0.5),
                        height: Math.floor(cur.height * 0.5)
                    };
                    octx.drawImage(oc, 0, 0, cur.width * 2, cur.height * 2, 0, 0, cur.width, cur.height);
                }

                // crop image to square
                let cropY = (cur.height - cur.width) / 2;
                ctx.drawImage(oc, 0, cropY, cur.width, cur.height - 2*cropY, 0, 0, canvas.width, canvas.height);

                // Show resized image in preview element
                document.getElementById("profile-picture-preview").src = canvas.toDataURL();
            }
            img.src = e.target.result;
        }
        reader.readAsDataURL(file);
    }
}

function submit()
{
    const about = document.getElementById('profile-about').value;
    if(about === "")
    {
        alert("Please tell us about you");
        return;
    }
    const b64_picture = document.getElementById("profile-picture-preview").src
    if(b64_picture === "")
    {
        alert("Please select a profile picture");
        return;
    }
    //console.log(b64_picture);
    Controller.signup(about, b64_picture);
}

function SignupView({ session, members })
{
    return (
        <div className={styles['col']}>
            <div className={styles['row']}>
                {session ?
                    <div className='container'>
                        <div className={styles['col']}>
                            {Object.hasOwn(members, String(session.actor)) ? <h2>Edit Profile</h2> : <h2>Sign-up</h2>}
                            <label htmlFor='profile-about'>Tell us about you:</label>
                            <textarea id='profile-about' rows={10}>{Object.hasOwn(members, String(session.actor)) ? members[String(session.actor)].about : ""}</textarea>
                            <label htmlFor='profile-picture-file'>Profile Picture:</label>
                            <input id='profile-picture-file' accept='image/*' type='file' onChange={previewImage}></input>
                            <img id='profile-picture-preview' className={styles['preview-img']} src={Object.hasOwn(members, String(session.actor)) ? members[String(session.actor)].b64_picture : profilePlaceholer} alt="Profile Picture" />
                            <div><button onClick={submit}>Submit</button></div>
                            <i>Note: You need to have at least <a href='https://www.greymass.com/anchor#download' target='_blank'><u>Anchor version 1.3.12</u></a> installed in order to submit large profile pictures.</i>
                        </div>
                    </div>
                :
                    <div>Please login in order to signup</div>
                }
            </div>
        </div>
    )
}

export default SignupView;