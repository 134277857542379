import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams } from "react-router-dom";
import styles from './ArchiveView.module.scss';
import mvStyles from './MembersView.module.scss';
import Controller from './Controller';
import placeholderVideo from './fractally-dao-hero.mp4';
import listViewIcon from './list-view.png';
import gridViewIcon from './grid-view.png';

function bn2Date(bn)
{
    let d = new Date(Controller.bn2ts(bn))
    let months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
    let year = d.getFullYear();
    let month = months[d.getMonth()];
    let day = d.getDate();
    return month + ' ' + day + ', ' + ' ' + year;
}
function bn2Time(bn)
{
    let d = new Date(Controller.bn2ts(bn))
    let hour = d.getHours();
    let min = d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes();
    let sec = d.getSeconds() < 10 ? '0' + d.getSeconds() : d.getSeconds();
    return hour + ':' + min + ':' + sec;
}

function GridView({ members, elections })
{
    const colors = ['#803E75', '#FFB300', '#FF6800', '#A6BDD7', '#C10020', '#CEA262', '#817066', '#007D34', '#F6768E', '#00538A', '#FF7A5C', '#53377A', '#FF8E00', '#B32851', '#F4C800', '#7F180D', '#93AA00', '#593315', '#F13A13', '#232C16'];
    var electionGrid = [];

    Object.entries(elections).forEach((e, i) => {
        //console.log(e[1]);
        let consensus_rankings = [];
        e[1].rooms.forEach(r => {
            consensus_rankings[r.id] = Controller.checkConsensus(e[1].rankings.filter(ranking => ranking.room === r.id).map(ranking => ranking.rankings), r.users.length).reverse();
        });

        electionGrid[i] = new Map();
        e[1].participants.forEach(p => {
            // for each participant determine room, respect & is_council
            let room = 0, respect = 0, is_council = false;
            e[1].rooms.forEach(r => { if(r.users.filter(u => u === p).length === 1) room = r.id; });
            respect = consensus_rankings[room].length > 0 ? Controller.respectAmount(6-1 - consensus_rankings[room].indexOf(p)) : 0;
            is_council = e[1].council.filter(u => u === p).length === 1;
            electionGrid[i].set(p, { room, respect, is_council });
        });
    })

    return (
        <div>
            <div className={styles['table-container']}>
                <table>
                    <tr>
                        <td></td>
                        {electionGrid.map((_, i) => <th>{i}</th>)}
                    </tr>
                    {Object.entries(members).map(m => {
                        return <tr>
                            <td><div className={styles['row-left']}><img src={m[1].b64_picture} className={styles['icon']} /><div>{m[0]}</div></div></td>
                            {electionGrid.map((e, i) => {
                                let avg_respect = 0;
                                let l = m[1].recent_respect.length; // window length of moving average
                                for(let j = (i >= (l-1) ? i-(l-1) : 0); j <= i; j++)
                                {
                                    avg_respect += electionGrid[j].has(m[0]) ? electionGrid[j].get(m[0]).respect : 0;
                                }
                                avg_respect /= l;
                                if(e.has(m[0]))
                                {
                                    return <td style={{
                                        backgroundColor: colors[e.get(m[0]).room - 1],
                                        border: e.get(m[0]).is_council ? '1px solid white' : '',
                                        textAlign: 'center'
                                    }}>
                                        {e.get(m[0]).respect}/{avg_respect.toFixed(2)}
                                    </td>
                                }
                                else
                                {
                                    return <td style={{
                                        border: elections[i].council.filter(u => u === m[0]).length === 1 ? '1px solid white' : '',
                                        textAlign: 'center'
                                    }}>
                                        0/{avg_respect.toFixed(2)}
                                    </td>
                                }
                            })}
                        </tr>
                    })}
                </table>
                <div className={mvStyles['panel']}>
                    <div class={mvStyles["panel-heading"]}>
                        <div class={mvStyles["panel-title"]}>Legend</div>
                    </div>
                    <div className={mvStyles['panel-body']}>
                        <div className={styles['row-left-wrap']}>
                            {[...Array(Object.entries(elections).map(e => e[1].rooms.length).reduce((max, n) => Math.max(max, n), 0)).keys()].map(i =>
                                <div><table><tr><td style={{ backgroundColor: colors[i], width: '4em' }}></td><td>Room {i+1}</td></tr></table></div>
                            )}
                            <div><table><tr><td style={{ border: '1px solid white', width: '4em' }}></td><td>Council Member</td></tr></table></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function ArchiveView({ members, elections })
{
    const params = useParams();
    const [gridView, setGridView] = useState(false);

    return (
        <div className={styles['column']}>
            <div className={styles['row']}>
                <div className='container'>
                {params.id ?
                    elections[params.id] ?
                        <div className={styles['column']}>
                            <div className={styles['row-sb']}>
                                <h2>Election {params.id}</h2>
                                <b>{elections[params.id].block_height}/{bn2Date(elections[params.id].block_height)} {bn2Time(elections[params.id].block_height)}</b>
                            </div>
                            <b className={mvStyles['panel-heading']}>Elected Council</b>
                            <div className={mvStyles['members-view']}>
                                {elections[params.id].council.map(m =>
                                    <Link to={'/members/' + m}>
                                        <div className={mvStyles['panel']}>
                                            <div className={mvStyles['panel-heading']}>
                                                <div>{m}</div>
                                                <div>{(members[m].recent_respect.reduce((Sum, a) => Sum + a, 0) / members[m].recent_respect.length).toFixed(2)}/{members[m].total_respect}</div>
                                            </div>
                                            <div className={mvStyles['panel-body']}>
                                                <img src={members[m].b64_picture} />
                                            </div>
                                        </div>
                                    </Link>
                                )}
                            </div>
                            {elections[params.id].rooms.map(r => {
                                let consensus_ranking = Controller.checkConsensus(elections[params.id].rankings.filter(ranking => ranking.room === r.id).map(ranking => ranking.rankings), r.users.length).reverse();
                                return(
                                    <div className={styles['column']}>
                                        <b className={mvStyles['panel-heading']}>Room {r.id}</b>
                                        <div className={styles['column-c']}>
                                            <video controls>
                                                <source src={'/recordings?election=' + elections[params.id].block_height + '&room=' + r.id} type="video/mp4" />
                                            </video>
                                            <div className={mvStyles['members-view']}>
                                                {r.users.map(u =>
                                                    <div className={styles['column']}>
                                                        <Link to={'/members/' + u}>
                                                            <div className={mvStyles['panel']}>
                                                                <div className={mvStyles['panel-heading']}>
                                                                    <div>{u}</div>
                                                                    <b>{consensus_ranking.length === 0 ? '0' : Controller.respectAmount(6-1 - consensus_ranking.indexOf(u))}</b>
                                                                </div>
                                                                <div className={mvStyles['panel-body']}>
                                                                    <img src={members[u].b64_picture} />
                                                                </div>
                                                            </div>
                                                        </Link>
                                                        <div className={styles['column']}>
                                                            {elections[params.id].rankings.filter(ranking => ranking.room === r.id && ranking.user === u).map(filtered => filtered.rankings.slice().reverse().map(rank =>
                                                                <Link to={'/members/' + rank}>
                                                                    <div className={styles['list-item']}>
                                                                        <img src={members[rank].b64_picture} />
                                                                        <div>{rank}</div>
                                                                    </div>
                                                                </Link>
                                                            ))}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            )}
                        </div>
                    :
                        <h3>Election does not exist. <Link to={'/archive'}>Go back.</Link></h3>
                :
                    <div className={styles['column']}>
                        <div className={styles['row-sb']}>
                            <h2>Election Archive</h2>
                            <img className={styles['icon']} src={gridView ? listViewIcon : gridViewIcon} onClick={() => {setGridView(!gridView)}} />
                        </div>
                        {gridView ?
                            <GridView members={members} elections={elections} />
                        :
                            Object.entries(elections).length === 0 ?
                                <div>No archived Elections yet.</div> :
                            Object.entries(elections).map(e =>
                                <Link to={'/archive/' + e[0]}>
                                    <div className={styles['list-item']}><div>Election {e[0]}</div><div>{bn2Date(e[1].block_height)}</div></div>
                                </Link>
                            )
                        }
                    </div>
                }
                </div>
            </div>
        </div>
    )
}

export default ArchiveView;