import logo from './upscale-logo.png';
import styles from './Header.module.scss';
import Controller from './Controller';
import { Link } from 'react-router-dom';

function Header({ session, isMember, joinedRoom, b64_picture })
{
    return (
        <header>
            <div className={styles['row']}>
                <img src={logo} />
                <div id={styles['app-info']}>
                    <h1>UpScale Fractal</h1>
                    <div>Your Launchpad to a Thriving Tech Universe</div>
                </div>
            </div>
            <div className={styles["row"]}>
                <div className={styles["menu"]}>
                    {!isMember ? <Link to="/signup">Sign-up</Link> : <></>}
                    <Link to="/">Election</Link>
                    <Link to="/members" target={joinedRoom ? "_blank" : "_self"}>Members</Link>
                    <Link to="/archive" target={joinedRoom ? "_blank" : "_self"}>Archive</Link>
                </div>
                <div className={styles['row']} id={styles["user-info"]}>
                    {session ? (
                        <>
                            <Link to={'/members/' + String(session.actor)} target={joinedRoom ? "_blank" : "_self"}><img src={b64_picture} /></Link>
                            <button id={styles["logout-button"]} onClick={Controller.logout}>Logout</button>
                        </>
                    ) : (
                        <button onClick={Controller.login}>Login</button>
                    )}
                </div>
            </div>
        </header>
    )
}

export default Header;