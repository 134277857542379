import styles from './App.module.scss';
import Controller from './Controller';
import React, { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import Header from './Header.js';
import ElectionView from './ElectionView.js'
import MembersView from './MembersView.js'
import ArchiveView from './ArchiveView.js'
import SignupView from './SignupView.js'

function App()
{
    const [hasLoaded, setHasLoaded] = useState(false);
    const [session, setSession] = useState(undefined);
    const [participants, setParticipants] = useState([]);
    const [rooms, setRooms] = useState([]);
    const [myRoom, setMyRoom] = useState(0);
    const [authToken, setAuthToken] = useState("");
    const [joinedRoom, setJoinedRoom] = useState(false);
    const [electionState, setElectionState] = useState({ cur: 0, pd: 0, rd: 0, nebh: 0, mnp: 0 });
    const [isLastElectionArchived, setIsLastElectionArchived] = useState(true);
    const [members, setMembers] = useState({});
    const [elections, setElections] = useState({});
    const [council, setCouncil] = useState([]);
    const [claims, setClaims] = useState([]);
    const [rewards, setRewards] = useState([]);
    useEffect(() => {
        Controller.setOnHasLoadedCallback(setHasLoaded);
        Controller.setOnUpdateSessionCallback(setSession);
        Controller.setOnParticipantsCallback(setParticipants);
        Controller.setOnRoomsCallback(setRooms);
        Controller.setOnMyRoomCallback(setMyRoom);
        Controller.setOnAuthTokenCallback(setAuthToken);
        Controller.setOnJoinedRoomCallback(setJoinedRoom);
        Controller.setOnElectionStateCallback(setElectionState);
        Controller.setOnIsLastElectionArchivedCallback(setIsLastElectionArchived);
        Controller.setOnMembersCallback(setMembers);
        Controller.setOnElectionsCallback(setElections);
        Controller.setOnCouncilCallback(setCouncil);
        Controller.setOnClaimsCallback(setClaims);
        Controller.setOnRewardsCallback(setRewards);
        Controller.restore();
    }, [])

    return (
        <div>
            <Header session={session} joinedRoom={joinedRoom} isMember={session ? Object.hasOwn(members, String(session.actor)) : false} b64_picture={session && Object.hasOwn(members, String(session.actor)) ? members[String(session.actor)].b64_picture : ""} />
                <div className={styles["app-body"]}>
                    {hasLoaded ?
                    <Routes>
                        <Route path="/" element={<ElectionView session={session} participants={participants} rooms={rooms} myRoom={myRoom} authToken={authToken} joinedRoom={joinedRoom} state={electionState} isLastElectionArchived={isLastElectionArchived} council={council} members={members} claims={claims} rewards={rewards} />} />
                        <Route path="/signup" element={<SignupView session={session} members={members} />} />
                        <Route path="/members" element={<MembersView session={session} elections={elections} members={members} />} />
                        <Route path="/members/:id" element={<MembersView session={session} elections={elections} members={members} />} />
                        <Route path="/archive" element={<ArchiveView elections={elections} members={members} />} />
                        <Route path="/archive/:id" element={<ArchiveView elections={elections} members={members} />} />
                    </Routes>
                    : <div>loading...</div>}
                </div>
        </div>
    );
}

export default App;
