import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams } from "react-router-dom";
import styles from './MembersView.module.scss';
import Controller from './Controller';
import pencilIcon from './pencil-edit-button.svg';
import listViewIcon from './list-view.png';
import gridViewIcon from './grid-view.png';
import { CompactTable } from "@table-library/react-table-library/compact";
import { getTheme } from "@table-library/react-table-library/baseline";
import { useSort } from "@table-library/react-table-library/sort";

function bn2Date(bn)
{
    let d = new Date(Controller.bn2ts(bn))
    let months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
    let year = d.getFullYear();
    let month = months[d.getMonth()];
    let day = d.getDate();
    return month + ' ' + day + ', ' + ' ' + year;
}

function MembersView({ session, members, elections })
{
    const [gridView, setGridView] = useState(true);
    const params = useParams();

    let nodes = [];
    Object.entries(members).forEach(m => {
        if(!m[1].is_banned)
        {
            nodes.push({
                user: m[0],
                b64_picture: m[1].b64_picture,
                signup_block: m[1].signup_block,
                is_approved: m[1].is_approved,
                num_elections: Object.entries(elections).reduce((Sum, e) => Sum + e[1].participants.filter(p => p === m[0]).length, 0),
                average_respect: m[1].recent_respect.reduce((Sum, a) => Sum + a, 0) / m[1].recent_respect.length,
                total_respect: m[1].total_respect
            })
        }
    });
    const data = { nodes };

    const sort = useSort(
        nodes,
        {
            onChange: () => {},
        },
        {
            sortFns: {
                NAME: (array) => array.sort((a, b) => a.user.localeCompare(b.user)),
                BLOCK: (array) => array.sort((a, b) => a.signup_block - b.signup_block),
                APPROVED: (array) => array.sort((a, b) => a.is_approved - b.is_approved),
                NUM: (array) => array.sort((a, b) => a.num_elections - b.num_elections),
                AVERAGE: (array) => array.sort((a, b) => a.average_respect - b.average_respect),
                TOTAL: (array) => array.sort((a, b) => a.total_respect - b.total_respect),
            },
        }
    );

    const COLUMNS = [
        {
          label: "Name",
          renderCell: (item) => <Link to={'/members/' + item.user}  className={styles['row-lc']}><img src={item.b64_picture} />{item.user}</Link>,
          sort: { sortKey: "NAME" },
        },
        {
          label: "Signup Date",
          renderCell: (item) => bn2Date(item.signup_block),
          sort: { sortKey: "BLOCK" },
        },
        {
          label: "Approved",
          renderCell: (item) => item.is_approved ? <>&#x1F7E2;</> : <>&#x1F7E1;</>,
          sort: { sortKey: "APPROVED" },
        },
        {
          label: "Elections",
          renderCell: (item) => item.num_elections,
          sort: { sortKey: "NUM" },
        },
        {
          label: "Average",
          renderCell: (item) => item.average_respect.toFixed(2),
          sort: { sortKey: "AVERAGE" },
        },
        {
          label: "Total",
          renderCell: (item) => item.total_respect,
          sort: { sortKey: "TOTAL" },
        },
    ];

    return (
        <div className={styles['column']}>
            <div className={styles['row']}>
                <div className='container'>
                {params.id ?
                    members[params.id] ?
                        <div className={styles['column']}>
                            <div className={styles['details-row']}>
                                <div className={styles['details-column-left']}>
                                    {session && String(session.actor) === params.id ?
                                        <Link to='/signup'>
                                            <div className={styles['profile-picture']}>
                                                <img src={pencilIcon} className={styles['pencil']} />
                                                <img src={members[params.id].b64_picture} />
                                            </div>
                                        </Link>
                                    :
                                        <img src={members[params.id].b64_picture} />
                                    }
                                    <h1>{params.id}</h1>
                                    <div>Average Respect: {(members[params.id].recent_respect.reduce((Sum, a) => Sum + a, 0) / members[params.id].recent_respect.length).toFixed(2)}</div>
                                    <div>Total Respect: {members[params.id].total_respect}</div>
                                </div>
                                <div className={styles['details-column-right']}>
                                    <b className={styles['panel-heading']}>Details</b>
                                    <div className={styles['details-column']}>
                                        <div className={styles['row']}><b>Sign-up Block/Date:</b> {members[params.id].signup_block + '/' + bn2Date(members[params.id].signup_block)} </div>
                                        <div className={styles['row']}><b>Approval Status:</b> {members[params.id].is_approved ? <>&#x1F7E2;</> : <>&#x1F7E1; {session ? <button onClick={() => Controller.approve(params.id)}>Approve</button> : <></>}</>}</div>
                                        <div className={styles['row']}><b>Banned:</b> {members[params.id].is_banned ? 'Yes' : <>No {session ? <button onClick={() => Controller.proposeBan(params.id)}>Propose Ban</button> : <></>}</>} {session ? <button onClick={() => Controller.proposeRemoval(params.id)}>Propose Removal</button> : <></>}</div>
                                        <div className={styles['row']}><b>Number of Elections:</b> {Object.entries(elections).reduce((Sum, e) => Sum + e[1].participants.filter(p => p === params.id).length, 0)}</div>
                                        <div className={styles['row']}><b>Recent Respect:</b> {'[' + members[params.id].recent_respect.map(v => v) + ']'}</div>
                                        <div className={styles['row']}><b>About:</b></div>
                                        <div className={styles['about']}>{members[params.id].about}</div>
                                    </div>
                                </div>
                            </div>
                            <b className={styles['panel-heading']}>Approved By</b>
                            <div className={styles['row-left']}>
                                {members[params.id].approvers.map(m => m === Controller.contractName ? <div className={styles['panel']}>{Controller.contractName}</div> :
                                    <Link to={'/members/' + m}>
                                        <div className={styles['panel']}>
                                            <div className={styles['panel-heading']}>
                                                <div>{m}</div>
                                                <div>{(members[m].recent_respect.reduce((Sum, a) => Sum + a, 0) / members[m].recent_respect.length).toFixed(2)}/{members[m].total_respect}</div>
                                            </div>
                                            <div className={styles['panel-body']}>
                                                <img src={members[m].b64_picture} />
                                            </div>
                                        </div>
                                    </Link>
                                )}
                            </div>
                            <b className={styles['panel-heading']}>Approved Members</b>
                            <div className={styles['row-left']}>
                                {Object.entries(members).map(m => m[1].approvers.some(user => user === params.id) ?
                                    <Link to={'/members/' + m[0]}>
                                        <div className={styles['panel']}>
                                            <div className={styles['panel-heading']}>
                                                <div>{m[0]}</div>
                                                {m[1].is_approved ? <div>{(m[1].recent_respect.reduce((Sum, a) => Sum + a, 0) / m[1].recent_respect.length).toFixed(2)}/{m[1].total_respect}</div> : <div>&#x1F7E1;</div>}
                                            </div>
                                            <div className={styles['panel-body']}>
                                                <img src={m[1].b64_picture} />
                                            </div>
                                        </div>
                                    </Link> : <></>
                                )}
                            </div>
                        </div>
                    :
                        <h3>Member does not exist. <Link to={'/members'}>Go back.</Link></h3>
                :
                    <div className={styles['column']}>
                        <div className={styles['row-sb']}>
                            {gridView ? <h2>Approved Members ({Object.entries(members).filter(e => e[1].is_approved).length})</h2> : <h2>All Members ({Object.entries(members).length})</h2>}
                            <img className={styles['icon']} src={gridView ? listViewIcon : gridViewIcon} onClick={() => {setGridView(!gridView)}} />
                        </div>
                        {gridView ?
                            <>
                                <div className={styles['members-view']}>
                                    {Object.entries(members).map(e => e[1].is_approved ?
                                        <Link to={'/members/' + e[0]}>
                                            <div className={styles['panel']}>
                                                <div className={styles['panel-heading']}>
                                                    <div>{e[0]}</div>
                                                    <div>{(e[1].recent_respect.reduce((Sum, a) => Sum + a, 0) / e[1].recent_respect.length).toFixed(2)}/{e[1].total_respect}</div>
                                                </div>
                                                <div className={styles['panel-body']}>
                                                    <img src={e[1].b64_picture} />
                                                </div>
                                            </div>
                                        </Link> : <></>
                                    )}
                                </div>
                                <h2>Pending Members ({Object.entries(members).filter(e => !e[1].is_approved).length})</h2>
                                <div className={styles['members-view']}>
                                    {Object.entries(members).map(e => !e[1].is_approved ?
                                        <Link to={'/members/' + e[0]}>
                                            <div className={styles['panel']}>
                                                <div className={styles['panel-heading']}>
                                                    <div>{e[0]}</div>
                                                    <div>&#x1F7E1;</div>
                                                </div>
                                                <div className={styles['panel-body']}>
                                                    <img src={e[1].b64_picture} />
                                                </div>
                                            </div>
                                        </Link> : <></>
                                    )}
                                </div>
                            </>
                        :
                            <div className={styles['members-table-view']}>
                                <CompactTable columns={COLUMNS} data={data} sort={sort} />
                            </div>
                        }
                    </div>
                }
                </div>
            </div>
        </div>
    )
}

export default MembersView;