import { SessionKit } from "@wharfkit/session"
import { WebRenderer } from "@wharfkit/web-renderer"
import { WalletPluginAnchor } from "@wharfkit/wallet-plugin-anchor"
import { Name, Serializer } from "@wharfkit/antelope"
import { TaskTimer } from 'tasktimer';
import Janus from './janus.js';
import $ from "jquery";
import micMuted from './mic_muted.png'
import micUnmuted from './mic_unmuted.png'
import camPublished from './cam_published.png'
import camUnpublished from './cam_unpublished.png'

// We make use of this 'server' variable to provide the address of the
// Janus API backend. By default, in this example we assume that Janus is
// co-located with the web server hosting the HTML pages but listening
// on a different port (8088, the default for HTTP in Janus), which is
// why we make use of the 'window.location.hostname' base address. Since
// Janus can also do HTTPS, and considering we don't really want to make
// use of HTTP for Janus if your demos are served on HTTPS, we also rely
// on the 'window.location.protocol' prefix to build the variable, in
// particular to also change the port used to contact Janus (8088 for
// HTTP and 8089 for HTTPS, if enabled).
// In case you place Janus behind an Apache frontend (as we did on the
// online demos at http://janus.conf.meetecho.com) you can just use a
// relative path for the variable, e.g.:
//
var server = "https://fractal.upscalenow.io:8089/janus";
//
// which will take care of this on its own.
//
// If you want to use the WebSockets frontend to Janus, instead (which
// is what we recommend, since they're more efficient than the long polling
// we do with HTTP), you'll have to pass a different kind of address, e.g.:
//
//         var server = "ws://" + window.location.hostname + ":8188";
//
// Of course this assumes that support for WebSockets has been built in
// when compiling the server. Notice that the "ws://" prefix assumes
// plain HTTP usage, so "wss://" should be used instead when using
// WebSockets on HTTPS.//
//
// If you have multiple options available, and want to let the library
// autodetect the best way to contact your server (or pool of servers),
// you can also pass an array of servers, e.g., to provide alternative
// means of access (e.g., try WebSockets first and, if that fails, fall
// back to plain HTTP) or just have failover servers:
//
//        var server = [
//            "ws://" + window.location.hostname + ":8188",
//            "/janus"
//        ];
//
// This will tell the library to try connecting to each of the servers
// in the presented order. The first working server will be used for
// the whole session.
//
//var server = null;
//if(window.location.protocol === 'http:')
//    server = "http://" + window.location.hostname + ":8088/janus";
//else
//    server = "https://" + window.location.hostname + ":8089/janus";

// When creating a Janus object, we can also specify which STUN/TURN
// servers we'd like to use to gather additional candidates. This is
// done by passing an "iceServers" property when creating the Janus
// object, meaning that the same set of servers will be used for all
// PeerConnections that will be initialized within the context of the
// new Janus session. When no iceServers object is provided, the janus.js
// library automatically uses the free Google STUN servers, which means
// it's equivalent to setting:
//
//        var iceServers = [{urls: "stun:stun.l.google.com:19302"}];
//
// Here are some examples of how an iceServers field may look like to
// support TURN instead. Notice that, when a TURN server is configured,
// there's no need to set a STUN one as well, since the TURN server will
// be automatically contacted as a STUN server too, meaning it will be
// used to gather both server reflexive and relay candidates.
//
//        var iceServers = [{urls: "turn:yourturnserver.com:3478", username: "janususer", credential: "januspwd"}]
//        var iceServers: [{urls: "turn:yourturnserver.com:443?transport=tcp", username: "janususer", credential: "januspwd"}]
//        var iceServers: [{urls: "turns:yourturnserver.com:443?transport=tcp", username: "janususer", credential: "januspwd"}]
//
// By default we leave the iceServers variable empty, which again means
// janus.js will fallback to the Google STUN server by default:
//
var iceServers = null;

//const contractName = 'fractally';
const contractName = 'thedaothedao';

const timer = new TaskTimer(500);
const webRenderer = new WebRenderer()
const sessionKit = new SessionKit({
    appName: contractName,
    chains: [
        //{
        //    id: "df0d1aacf71a6d61b11eee7b0e52cc54302b18f76346c7287f8c252a3de172f2",
        //    //url: "http://localhost:8888",
        //    //url: "http://" + window.location.hostname + ":8888",
        //    url: "http://vmd109432.contaboserver.net:8888"
        //},
        //{
        //    id: '73e4385a2708e6d7048834fbc1079f2fabb17b3c125b146af438971e90716c4d',
        //    url: 'https://jungle4.cryptolions.io',
        //},
        {
            id: 'aca376f206b8fc25a6ed44dbdc66547c36c6c33e3a119ffbeaef943642f0e906',
            url: 'https://eos.api.eosnation.io/',
        },

    ],
    ui: webRenderer,
    walletPlugins: [new WalletPluginAnchor()]
})
var session = undefined;
var global = null;
var block_num = 0;
var rooms = [];
var myToken = "";
var myRoom = 0;
var myRanking = [];
var joinedRoom = false;
var members = {};
var elections = {};
var council = [];
var claims = [];
var rewards = [];
var published = true;
var is_talking = new Map();
var talking_time = new Map();

// Janus stuff
var janus = null;
var sfutest = null;
var opaqueId = contractName + Janus.randomString(12);
var myid = null;
var mystream = null;
var mypvtid = null; // We use this other ID just to map our subscriptions to us
var localTracks = {}, localVideos = 0;
var feeds = [], feedStreams = {};
var bitrateTimer = [];
//var doSimulcast = (getQueryStringValue("simulcast") === "yes" || getQueryStringValue("simulcast") === "true");
var acodec = (getQueryStringValue("acodec") !== "" ? getQueryStringValue("acodec") : null);
var vcodec = (getQueryStringValue("vcodec") !== "" ? getQueryStringValue("vcodec") : null);
var doDtx = (getQueryStringValue("dtx") === "yes" || getQueryStringValue("dtx") === "true");
var subscriber_mode = (getQueryStringValue("subscriber-mode") === "yes" || getQueryStringValue("subscriber-mode") === "true");
var use_msid = (getQueryStringValue("msid") === "yes" || getQueryStringValue("msid") === "true");

const STATE_IDLE = 0;
const STATE_PARTICIPATE = 1;
const STATE_ROOMS = 2;

async function fetchMembers()
{
    const res = await fetch(window.location.origin + '/members', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({})
    })
    let members = await res.json();
    return members;
}

async function fetchElections()
{
    const res = await fetch(window.location.origin + '/elections', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({})
    })
    let elections = await res.json();
    return elections;
}

async function fetchGlobal()
{
    const res = await fetch(window.location.origin + '/global', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({})
    })
    let global = await res.json();
    return global;
}

async function fetchParticipants()
{
    const res = await fetch(window.location.origin + '/participants', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({})
    })
    let participants = await res.json();
    return participants;
}

async function fetchRooms()
{
    const res = await fetch(window.location.origin + '/rooms', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({})
    })
    let rooms = await res.json();
    return rooms;
}

async function fetchCouncil()
{
    const res = await fetch(window.location.origin + '/council', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({})
    })
    let council = await res.json();
    return council;
}

async function fetchClaims()
{
    if(!session) return [];
    const res = await fetch(window.location.origin + '/claims', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user: String(session.actor) })
    })
    let claims = await res.json();
    return claims;
}

async function fetchRewards()
{
    if(!session) return [];
    const res = await fetch(window.location.origin + '/rewards', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user: String(session.actor) })
    })
    let rewards = await res.json();
    return rewards;
}

async function fetchPreviousElection()
{
    const res = await fetch(window.location.origin + '/previouselection', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({})
    })
    let election = await res.json();
    return election;
}

async function fetchElection(id)
{
    const res = await fetch(window.location.origin + '/election', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id })
    })
    let election = await res.json();
    return election;
}

function shuffle(array)
{
    for(let i = array.length - 1; i > 0; i--)
    {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
}

function fib(index)
{
    return (index <= 1) ? index : fib(index - 1) + fib(index - 2);
};

function evaluateConsensus(others_rankings)
{
    // consensus rule for different group sizes:
    const CONSENSUS = [0, 1, 2, 2, 3, 3, 4];
    const GROUP_SIZE = myRanking.length;

    let emojis = [];
    let all_rankings = [myRanking.map(e => e.id), ...others_rankings];
    // walk through all positions (0 to GROUP_SIZE <=> highest to lowest)
    for(let pos = 0; pos < GROUP_SIZE; pos++)
    {
        // walk thruogh all the different user rankings and count how many rankings have the same
        // user at this particular position
        let consensus_map = new Map();
        for(let i = 0; i < GROUP_SIZE; i++)
        {
            // make sure array isn't empty (i.e. the particular user has submitted a ranking already)
            if(all_rankings[i].length == GROUP_SIZE)
            {
                if(consensus_map.has(all_rankings[i][pos]))
                {
                    consensus_map.set(all_rankings[i][pos], consensus_map.get(all_rankings[i][pos]) + 1);
                }
                else
                {
                    consensus_map.set(all_rankings[i][pos], 1);
                }
            }
        }
        // walk through the consensus map and check if there is a user that has enough counts for
        // the group to reach consensus, then determine if I am part of the consensus
        emojis[pos] = String.fromCodePoint(0x1F534); // shrug(0x1F937) or red circle(0x1F534)
        consensus_map.forEach((value, key) =>
        {
            // unanimous consensus?
            if(value == GROUP_SIZE)
            {
                emojis[pos] = String.fromCodePoint(0x1F7E2); // 100(0x1F4AF) or green circle(0x1F7E2)
            }
            // consensus reached?
            else if(value >= CONSENSUS[GROUP_SIZE])
            {
                // am I part of the consensus?
                if(myRanking[pos].id == key)
                {
                    emojis[pos] = String.fromCodePoint(0x1F7E1); // Smiling Face With Smiling Eyes(0x1F60A) or yellow circle(0x1F7E1)
                }
                else // consensus, but I am not part of it
                {
                    emojis[pos] = String.fromCodePoint(0x1F7E0); // confused/thinking(0x1F914) or orange circle(0x1F7E0)
                }
            }
        });
    }

    // update the emojis
    onEmojis(emojis);
}

// callback functions for views
var onHasLoaded = function() {};
var onUpdateSession = function() {};
var onBlockNum = function() {};
var onElectionState = function() {};
var onIsLastElectionArchived = function() {};
var onParticipants = function() {};
var onRooms = function() {};
var onMyRoom = function() {};
var onAuthToken = function() {};
var onJoinedRoom = function() {};
var onRanking = function() {};
var onEmojis = function() {};
var onMembers = function() {};
var onElections = function() {};
var onCouncil = function() {};
var onClaims = function() {};
var onRewards = function() {};

// initialize controller
(async () => {
    global = await fetchGlobal();
    members = await fetchMembers();
    onMembers(members);
    elections = await fetchElections();
    onElections(elections);
    council = await fetchCouncil();
    onCouncil(council);
    rewards = await fetchRewards();
    onRewards(rewards);
    block_num = global.block_num;
    onBlockNum(block_num);
    onElectionState({ cur: global.state, pd: global.participate_duration, rd: global.rooms_duration, nebh: global.next_event_block_height, mnp: global.min_num_participants, rbn: global.ref_bn, rts: global.ref_ts });
    timer.add([
        {
            id: 'heartbeat',
            tickInterval: 4,    // 2 seconds
            totalRuns: 0,
            async callback(task)
            {
                let old_event_count = global.event_archive_blocks.length;
                global = await fetchGlobal();
                block_num = global.block_num;
                onBlockNum(block_num);
                onElectionState({ cur: global.state, pd: global.participate_duration, rd: global.rooms_duration, nebh: global.next_event_block_height, mnp: global.min_num_participants, rbn: global.ref_bn, rts: global.ref_ts });
                onIsLastElectionArchived(global.event_count === global.event_archive_blocks.length);

                switch(global.state)
                {
                    case STATE_IDLE:
                    {
                        if(rooms.length !== 0)
                        {
                            rooms = [];
                            onRooms(rooms);
                        }
                        if(myRoom !== 0)
                        {
                            myRoom = 0;
                            onMyRoom(myRoom);
                        }
                        if(myRanking.length !== 0)
                        {
                            myRanking = [];
                            onRanking(myRanking);
                        }

                        // if a new election has been archived we need to update elections, members, council, claims & rewards
                        if(old_event_count < global.event_archive_blocks.length)
                        {
                            elections = await fetchElections();
                            onElections(elections);
                            members = await fetchMembers();
                            onMembers(members);
                            council = await fetchCouncil();
                            onCouncil(council);
                            claims = await fetchClaims();
                            onClaims(claims);
                            rewards = await fetchRewards();
                            onRewards(rewards);
                        }
                    } break;

                    case STATE_PARTICIPATE:
                    {
                        let participants = await fetchParticipants();
                        onParticipants(participants);
                        is_talking.clear();
                        talking_time.clear();
                    } break;

                    case STATE_ROOMS:
                    {
                        if(rooms.length === 0)
                        {
                            rooms = await fetchRooms();
                            onRooms(rooms);
                        }

                        if(session && myRoom === 0)
                        {
                            rooms.forEach(r => {
                                if(r.users.filter(u => u === String(session.actor)).length > 0)
                                {
                                    myRoom = r.id;
                                    onMyRoom(myRoom);
                                }
                            });
                        }

                        if(myRanking.length > 0)
                        {
                            // keep updating the consensus smileys
                            $.get("/rankings", { room: myRoom, user: String(session.actor) }, function(data)
                            {
                                evaluateConsensus(data);
                            });
                        }
                    } break;
                }
            },
        },
        {
            id: 'refresh_tables',
            tickInterval: 30, // 15 seconds
            totalRuns: 0,
            async callback(task)
            {
                members = await fetchMembers();
                onMembers(members);
                rewards = await fetchRewards();
                onRewards(rewards);
            }
        },
        {
            id: 'block_num',
            tickInterval: 1,
            totalRuns: 0,
            callback(task)
            {
                block_num++;
                onBlockNum(block_num);

                if(global.state === STATE_ROOMS)
                {
                    // measure talking time of each room participant
                    is_talking.forEach((value, key, map) => {
                        if(value)
                        {
                            if(talking_time.has(key))
                            {
                                let time = talking_time.get(key);
                                time.ms += 500;
                                if(time.ms >= 1000) { time.s++; time.ms -= 1000; }
                                if(time.s >= 60)    { time.m++; time.s  -= 60;   }
                                if(time.m >= 60)    { time.h++; time.m  -= 60;   }
                                talking_time.set(key, time)
                                let display_time = (time.h < 10 ? "0" + time.h : time.h) + ":" + (time.m < 10 ? "0" + time.m : time.m) + ":" + (time.s < 10 ? "0" + time.s : time.s);// + ":" + (time.ms < 100 ? "00" + time.ms : time.ms);
                                let div_id = "clocklocal";
                                for(let i=1; i<6; i++)
                                {
                                    if(feeds[i] && feeds[i].rfdisplay === key) {
                                        div_id = "clockremote" + feeds[i].rfindex;
                                        break;
                                    }
                                }
                                document.getElementById(div_id).innerText = display_time;
                                document.getElementById(div_id).textContent = display_time;
                            }
                            else
                            {
                                talking_time.set(key, { h: 0, m: 0, s: 0, ms: 0 });
                            }
                        }
                    });
                }
            }
        }
    ]);
    timer.start();
    onHasLoaded(true);
    console.log("app loaded...")
})();

export default {

    // callback functions
    setOnHasLoadedCallback: (callback) => { onHasLoaded = callback },
    setOnUpdateSessionCallback: (callback) => { onUpdateSession = callback },
    setOnBlockNumCallback: (callback) => { onBlockNum = callback },
    setOnElectionStateCallback: (callback) => { onElectionState = callback },
    setOnIsLastElectionArchivedCallback: (callback) => { onIsLastElectionArchived = callback },
    setOnParticipantsCallback: (callback) => { onParticipants = callback },
    setOnRoomsCallback: (callback) => { onRooms = callback },
    setOnMyRoomCallback: (callback) => { onMyRoom = callback },
    setOnAuthTokenCallback: (callback) => { onAuthToken = callback },
    setOnJoinedRoomCallback: (callback) => { onJoinedRoom = callback },
    setOnRankingCallback: (callback) => { onRanking = callback },
    setOnEmojisCallback: (callback) => { onEmojis = callback },
    setOnMembersCallback: (callback) => { onMembers = callback },
    setOnElectionsCallback: (callback) => { onElections = callback },
    setOnCouncilCallback: (callback) => { onCouncil = callback },
    setOnClaimsCallback: (callback) => { onClaims = callback },
    setOnRewardsCallback: (callback) => { onRewards = callback },

    bn2ts: function(bn) { return global.ref_ts + (bn - global.ref_bn) * 500; },
    ts2bn: function(ts) { return global.ref_bn + (ts - global.ref_ts) / 500; },
    contractName: contractName,

    login: async function()
    {
        const response = await sessionKit.login()
        session = response.session
        onUpdateSession(session)
        claims = await fetchClaims();
        onClaims(claims);
    },
    logout: async function()
    {
        await sessionKit.logout(session)
        session = undefined
        onUpdateSession(session)
        if(joinedRoom)
        {
            janus.destroy();
        }
        myToken = "";
        onAuthToken(myToken);
        myRoom = 0;
        onMyRoom(myRoom);
        myRanking = [];
        onRanking(myRanking);
    },
    restore: async function()
    {
        session = await sessionKit.restore()
        onUpdateSession(session)
        claims = await fetchClaims();
        onClaims(claims);
    },

    signup: function(about, b64_picture)
    {
        if(!session) return;
        const action = {
            account: contractName,
            name: 'signup',
            authorization: [session.permissionLevel],
            data: {
                user: String(session.actor),
                about,
                b64_picture
            }
        }
        session.transact({ action }, { broadcast: true }).then((result) =>
        {
            console.log(result);
            setTimeout(() => {
                $.post('/updatemembers', {}, function(data, status, xhr){
                    members = data;
                    onMembers(members);
                }, 'json');
            }, 3000);
        })
    },
    onDropRank: function(oldIdx, newIdx)
    {
        let tmp = myRanking[oldIdx];
        myRanking[oldIdx] = myRanking[newIdx];
        myRanking[newIdx] = tmp;
        $.post(
            '/rankings',
            { user: String(session.actor), sig: myToken, room: myRoom, ranking: myRanking.map(e => e.id) },
            function(data, status, xhr)
            {
                evaluateConsensus(data);
            },
            'json'
        );
        onRanking(myRanking);
    },
    submitRanks: function()
    {
        const action = {
            account: contractName,
            name: 'submitranks',
            authorization: [session.permissionLevel],
            data: {
                user: String(session.actor),
                room: myRoom,
                rankings: myRanking.map(e => e.id).slice().reverse()
            }
        }
        session.transact({ action }, { broadcast: true }).then((result) =>
        {
            console.log(result);
        })
    },
    approve: function(user_to_approve)
    {
        const action = {
            account: contractName,
            name: 'approve',
            authorization: [session.permissionLevel],
            data: {
                user: String(session.actor),
                user_to_approve
            }
        }
        session.transact({ action }, { broadcast: true }).then((result) =>
        {
            console.log(result);
            setTimeout(() => {
                $.post('/updatemembers', {}, function(data, status, xhr){
                    members = data;
                    onMembers(members);
                }, 'json');
            }, 3000);
        })
    },
    archiveElection: async function()
    {
        let election = await fetchPreviousElection();
        const action = {
            account: contractName,
            name: 'archiveevent',
            authorization: [session.permissionLevel],
            data: {
                block_height: election.block_height,
                participants: election.participants,
                rooms: election.rooms,
                rankings: election.rankings,
                council: election.council
            }
        }
        session.transact({ action }, { broadcast: true }).then((result) =>
        {
            console.log(result);
        })
    },
    proposeNewElectionBlockHeight: function(datetime)
    {
        $.post('/proposeblockheight', { datetime }, function(data, status, xhr){
            data.proposer = String(session.actor);
            const action = {
                account: "eosio.msig",
                name: "propose",
                authorization: [session.permissionLevel],
                data,
            };
            session.transact({ action }, { broadcast: true }).then((result) =>
            {
                console.log(result);
                console.log("visit: https://bloks.io/msig/" + String(session.actor) + "/fracsetevent");
                alert("Success! Open the JS console (ctrl + shift + i) to find the link to your msig proposal (https://bloks.io/msig/" + String(session.actor) + "/fracsetevent)");
            })
        }, 'json');
    },
    proposeBan: function(user)
    {
        $.post('/proposeban', { user }, function(data, status, xhr){
            data.proposer = String(session.actor);
            const action = {
                account: "eosio.msig",
                name: "propose",
                authorization: [session.permissionLevel],
                data,
            };
            session.transact({ action }, { broadcast: true }).then((result) =>
            {
                console.log(result);
                console.log("visit: https://bloks.io/msig/" + String(session.actor) + "/fracbanuser");
                alert("Success! Open the JS console (ctrl + shift + i) to find the link to your msig proposal (https://bloks.io/msig/" + String(session.actor) + "/fracbanuser)");
            })
        }, 'json');
    },
    proposeRemoval: function(user)
    {
        $.post('/proposerm', { user }, function(data, status, xhr){
            data.proposer = String(session.actor);
            const action = {
                account: "eosio.msig",
                name: "propose",
                authorization: [session.permissionLevel],
                data,
            };
            session.transact({ action }, { broadcast: true }).then((result) =>
            {
                console.log(result);
                console.log("visit: https://bloks.io/msig/" + String(session.actor) + "/fracrmuser");
                alert("Success! Open the JS console (ctrl + shift + i) to find the link to your msig proposal (https://bloks.io/msig/" + String(session.actor) + "/fracrmuser)");
            })
        }, 'json');
    },
    claimRewards: function()
    {
        const action = {
            account: contractName,
            name: 'claimrewards',
            authorization: [session.permissionLevel],
            data: {
                user: String(session.actor)
            }
        }
        session.transact({ action }, { broadcast: true }).then((result) =>
        {
            console.log(result);
            setTimeout(async () => {
                claims = await fetchClaims();
                onClaims(claims);
            }, 3000)
        })
    },
    respectAmount: function(rankIndex)
    {
        return fib(global.fib_offset + rankIndex);
    },
    // this consensus check algorithm MUST be equivalent to the one of the smart contract
    checkConsensus: function(submissions, num_room_users)
    {
        let consensus_ranking = [];
        let has_consensus = true;

        // Check consensus for this group
        for(let i = 0; i < num_room_users; i++)
        {
            let counts = new Map();
            let most_voted_name = "";
            let most_voted_count = 0;

            // Count occurrences and identify the most common account at the same time
            for(const submission of submissions)
            {
                if(!counts.has(submission[i])) counts.set(submission[i], 1);
                else counts.set(submission[i], counts.get(submission[i]) + 1);

                // Update most_voted_name if necessary
                if(counts.get(submission[i]) > most_voted_count)
                {
                    most_voted_name = submission[i];
                    most_voted_count = counts.get(submission[i]);
                }
            }

            // Check consensus for the current rank
            if(most_voted_count * 3 >= num_room_users * 2)
            {
                consensus_ranking.push(most_voted_name);
            }
            else
            {
                has_consensus = false;
                break;
            }
        }

        if(has_consensus)
        {
            return consensus_ranking;
        }
        return [];
    },
    changeState: function()
    {
        const action = {
            account: contractName,
            name: 'changestate',
            authorization: [session.permissionLevel],
            data: {}
        }
        session.transact({ action }, { broadcast: true }).then((result) =>
        {
            console.log(result);
        })
    },
    joinEvent: function()
    {
        const action = {
            account: contractName,
            name: 'participate',
            authorization: [session.permissionLevel],
            data: {
                user: String(session.actor)
            }
        }
        session.transact({ action }, { broadcast: true }).then((result) =>
        {
            console.log(result);
        })
    },
    randomize: function()
    {
        const action = {
            account: contractName,
            name: 'generate',
            authorization: [session.permissionLevel],
            data: {
                salt: Math.floor(Math.random() * Number.MAX_SAFE_INTEGER)
            }
        }
        session.transact({ action }, { broadcast: true }).then((result) =>
        {
            console.log(result);
        })
    },
    authenticate: function()
    {
        const action = {
            account: contractName,
            name: 'authenticate',
            authorization: [session.permissionLevel],
            data: {
                user: String(session.actor),
                event: global.event_count,
                room: myRoom,
            }
        }
        session.transact({ action }, { broadcast: false }).then(async (result) =>
        {
            // because wharfkit isn't serializing the action data (like Anchor does) we have to do it manually
            const encoded = Serializer.encode({
                object: result.transaction.actions[0].data,
                type: {user: Name, event: Number, room: Number},
            })
            result.transaction.actions[0].data = encoded;
            const res = await fetch(window.location.origin + '/authenticate', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    tx: JSON.parse(JSON.stringify(result.transaction)),
                    sig: JSON.parse(JSON.stringify(result.signatures[0]))
                })
            })
            myToken = (await res.json()).auth_token;
            onAuthToken(myToken);
            if(myToken === "")
            {
                alert("not allowed to join");
            }
        })
    },
    exitRoom: function()
    {
        janus.destroy();
    },
    joinRoom: function()
    {
        // initialize myRanking on first join
        if(myRanking.length === 0)
        {
            rooms.filter(r => r.id === myRoom)[0].users.forEach((m, i) => {
                myRanking[i] = {
                    id: m,
                    content: {
                        b64_picture: members[m].b64_picture,
                        avg: (members[m].recent_respect.reduce((Sum, a) => Sum + a, 0) / members[m].recent_respect.length).toFixed(2),
                        ttl: members[m].total_respect
                    }
                }
            });
            shuffle(myRanking);
            $.post(
                '/rankings',
                { user: String(session.actor), sig: myToken, room: myRoom, ranking: myRanking.map(e => e.id) },
                function(data, status, xhr)
                {
                    evaluateConsensus(data);
                },
                'json'
            );
        }
        onRanking(myRanking);
        // fetch initial talking times from server
        $.get(
            '/talking_time',
            { room: myRoom },
            function(data, status, xhr)
            {
                //console.log(data);
                Object.entries(data).map(e => {
                    talking_time.set(e[0], e[1]);
                });
            },  
            'json'
        );

        // Initialize the library (all console debuggers enabled)
        Janus.init({debug: "all", callback: function()
        {
            // Make sure the browser supports WebRTC
            if(!Janus.isWebrtcSupported())
            {
                alert("No WebRTC support... ");
                return;
            }
            // Create session
            janus = new Janus({
                server: server,
                iceServers: iceServers,
                // Should the Janus API require authentication, you can specify either the API secret or user token here too
                //        token: "mytoken",
                //    or
                //        apisecret: "serversecret",
                success: function()
                {
                    // Attach to VideoRoom plugin
                    janus.attach(
                    {
                        plugin: "janus.plugin.videoroom",
                        opaqueId: opaqueId,
                        success: function(pluginHandle)
                        {
                            sfutest = pluginHandle;
                            Janus.log("Plugin attached! (" + sfutest.getPlugin() + ", id=" + sfutest.getId() + ")");
                            Janus.log("  -- This is a publisher/manager");
                            var register = {
                                request: "join",
                                room: myRoom,
                                ptype: "publisher",
                                display: String(session.actor),
                                token: myToken
                            };
                            sfutest.send({ message: register });
                        },
                        error: function(error)
                        {
                            Janus.error("  -- Error attaching plugin...", error);
                            alert("Error attaching plugin... " + error);
                        },
                        consentDialog: function(on)
                        {
                            Janus.debug("Consent dialog should be " + (on ? "on" : "off") + " now");
                            //if(on)
                            //{
                            //    // Darken screen and show hint
                            //    $.blockUI({
                            //        message: '<div><img src="up_arrow.png"/></div>',
                            //        css: {
                            //            border: 'none',
                            //            padding: '15px',
                            //            backgroundColor: 'transparent',
                            //            color: '#aaa',
                            //            top: '10px',
                            //            left: (navigator.mozGetUserMedia ? '-100px' : '300px')
                            //        }
                            //    });
                            //}
                            //else
                            //{
                            //    // Restore screen
                            //    $.unblockUI();
                            //}
                        },
                        iceState: function(state)
                        {
                            Janus.log("ICE state changed to " + state);
                        },
                        mediaState: function(medium, on, mid)
                        {
                            Janus.log("Janus " + (on ? "started" : "stopped") + " receiving our " + medium + " (mid=" + mid + ")");
                        },
                        webrtcState: function(on)
                        {
                            Janus.log("Janus says our WebRTC PeerConnection is " + (on ? "up" : "down") + " now");
                            //$("#videolocal").parent().parent().unblock();
                            if(!on)
                                return;
                        },
                        slowLink: function(uplink, lost, mid)
                        {
                            Janus.warn("Janus reports problems " + (uplink ? "sending" : "receiving") +
                                " packets on mid " + mid + " (" + lost + " lost packets)");
                        },
                        onmessage: function(msg, jsep)
                        {
                            Janus.debug(" ::: Got a message (publisher) :::", msg);
                            var event = msg["videoroom"];
                            Janus.debug("Event: " + event);
                            if(event)
                            {
                                if(event === "joined")
                                {
                                    // Publisher/manager created, negotiate WebRTC and attach to existing feeds, if any
                                    myid = msg["id"];
                                    mypvtid = msg["private_id"];
                                    Janus.log("Successfully joined room " + msg["room"] + " with ID " + myid);
                                    if(subscriber_mode)
                                    {
                                        //$('#videos').removeClass('hide').show();
                                    }
                                    else
                                    {
                                        publishOwnFeed(true);
                                    }
                                    // Any new feed to attach to?
                                    if(msg["publishers"])
                                    {
                                        var list = msg["publishers"];
                                        Janus.debug("Got a list of available publishers/feeds:", list);
                                        for(var f in list)
                                        {
                                            if(list[f]["dummy"])
                                                continue;
                                            var id = list[f]["id"];
                                            var streams = list[f]["streams"];
                                            var display = list[f]["display"];
                                            for(var i in streams)
                                            {
                                                var stream = streams[i];
                                                stream["id"] = id;
                                                stream["display"] = display;
                                            }
                                            feedStreams[id] = streams;
                                            Janus.debug("  >> [" + id + "] " + display + ":", streams);
                                            newRemoteFeed(id, display, streams);
                                        }
                                    }
                                } else if(event === "destroyed") {
                                    // The room has been destroyed
                                    Janus.warn("The room has been destroyed!");
                                    janus.destroy();
                                    myToken = "";
                                    onAuthToken(myToken);
                                    myRoom = 0;
                                    onMyRoom(myRoom);
                                    myRanking = [];
                                    onRanking(myRanking);
                                    alert("The room has been destroyed", function() {
                                        //window.location.reload();
                                    });
                                } else if(event === "event") {
                                    // Any info on our streams or a new feed to attach to?
                                    if(msg["streams"]) {
                                        var streams = msg["streams"];
                                        for(var i in streams) {
                                            var stream = streams[i];
                                            stream["id"] = myid;
                                            stream["display"] = String(session.actor); //myusername;
                                        }
                                        feedStreams[myid] = streams;
                                    } else if(msg["publishers"]) {
                                        var list = msg["publishers"];
                                        Janus.debug("Got a list of available publishers/feeds:", list);
                                        for(var f in list) {
                                            if(list[f]["dummy"])
                                                continue;
                                            var id = list[f]["id"];
                                            var display = list[f]["display"];
                                            var streams = list[f]["streams"];
                                            for(var i in streams) {
                                                var stream = streams[i];
                                                stream["id"] = id;
                                                stream["display"] = display;
                                            }
                                            feedStreams[id] = streams;
                                            Janus.debug("  >> [" + id + "] " + display + ":", streams);
                                            newRemoteFeed(id, display, streams);
                                        }
                                    } else if(msg["leaving"]) {
                                        // One of the publishers has gone away?
                                        var leaving = msg["leaving"];
                                        Janus.log("Publisher left: " + leaving);
                                        var remoteFeed = null;
                                        for(var i=1; i<6; i++) {
                                            if(feeds[i] && feeds[i].rfid == leaving) {
                                                remoteFeed = feeds[i];
                                                break;
                                            }
                                        }
                                        if(remoteFeed) {
                                            Janus.debug("Feed " + remoteFeed.rfid + " (" + remoteFeed.rfdisplay + ") has left the room, detaching");
                                            $('#remote'+remoteFeed.rfindex).empty();//.hide();
                                            $('#videoremote'+remoteFeed.rfindex).empty();
                                            feeds[remoteFeed.rfindex] = null;
                                            remoteFeed.detach();
                                        }
                                        delete feedStreams[leaving];
                                    } else if(msg["unpublished"]) {
                                        // One of the publishers has unpublished?
                                        var unpublished = msg["unpublished"];
                                        Janus.log("Publisher left: " + unpublished);
                                        if(unpublished === 'ok') {
                                            // That's us
                                            sfutest.hangup();
                                            published = false;
                                            $('#unpublish').attr('src', camUnpublished);
                                            return;
                                        }
                                        var remoteFeed = null;
                                        for(var i=1; i<6; i++) {
                                            if(feeds[i] && feeds[i].rfid == unpublished) {
                                                remoteFeed = feeds[i];
                                                break;
                                            }
                                        }
                                        if(remoteFeed) {
                                            Janus.debug("Feed " + remoteFeed.rfid + " (" + remoteFeed.rfdisplay + ") has left the room, detaching");
                                            $('#remote'+remoteFeed.rfindex).empty();//.hide();
                                            $('#videoremote'+remoteFeed.rfindex).empty();
                                            feeds[remoteFeed.rfindex] = null;
                                            remoteFeed.detach();
                                        }
                                        delete feedStreams[unpublished];
                                    } else if(msg["error"]) {
                                        if(msg["error_code"] === 426) {
                                            // This is a "no such room" error: give a more meaningful description
                                            alert(
                                                "<p>Apparently room <code>" + myRoom + "</code> (the one this demo uses as a test room) " +
                                                "does not exist...</p><p>Do you have an updated <code>janus.plugin.videoroom.jcfg</code> " +
                                                "configuration file? If not, make sure you copy the details of room <code>" + myRoom + "</code> " +
                                                "from that sample in your current configuration file, then restart Janus and try again."
                                            );
                                        } else {
                                            alert(msg["error"]);
                                        }
                                    }
                                } else if(event === "talking") {
                                    if(msg["room"] === myRoom)
                                    {
                                        if(msg["id"] === myid)
                                        {
                                            console.log("I am talking (vol: " + msg["audio-level-dBov-avg"] + ")");
                                            document.getElementById('videolocal').parentElement.style.borderColor = "#2a82da";
                                            document.getElementById('videolocal').parentElement.style.borderRadius = "5px";
                                            document.getElementById('videolocal').parentElement.style.boxShadow = "0 0 5px 0px #2a82da";
                                            is_talking.set(String(session.actor), true);
                                        }
                                        else
                                        {
                                            for(let i=1; i<6; i++)
                                            {
                                                if(feeds[i] && feeds[i].rfid == msg["id"]) {
                                                    console.log(feeds[i].rfdisplay + " is talking (vol: " + msg["audio-level-dBov-avg"] + ")");
                                                    document.getElementById('videoremote'+i).parentElement.style.borderColor = "#2a82da";
                                                    document.getElementById('videoremote'+i).parentElement.style.borderRadius = "5px";
                                                    document.getElementById('videoremote'+i).parentElement.style.boxShadow = "0 0 5px 0px #2a82da";
                                                    is_talking.set(feeds[i].rfdisplay, true);
                                                    break;
                                                }
                                            }
                                        }
                                    }
                                } else if(event === "stopped-talking") {
                                    if(msg["room"] === myRoom)
                                    {
                                        if(msg["id"] === myid)
                                        {
                                            console.log("I stopped talking (vol: " + msg["audio-level-dBov-avg"] + ")");
                                            document.getElementById('videolocal').parentElement.style.borderColor = "";
                                            document.getElementById('videolocal').parentElement.style.borderRadius = "";
                                            document.getElementById('videolocal').parentElement.style.boxShadow = "";
                                            is_talking.set(String(session.actor), false);
                                            // update our time on server
                                            $.post(
                                                '/talking_time',
                                                { room: myRoom, user: String(session.actor), time: talking_time.get(String(session.actor)) },
                                                function(data, status, xhr)
                                                {
                                                    //console.log(data);
                                                },
                                                'json'
                                            );
                                        }
                                        else
                                        {
                                            for(let i=1; i<6; i++)
                                            {
                                                if(feeds[i] && feeds[i].rfid == msg["id"]) {
                                                    console.log(feeds[i].rfdisplay + " stopped talking (vol: " + msg["audio-level-dBov-avg"] + ")");
                                                    document.getElementById('videoremote'+i).parentElement.style.borderColor = "";
                                                    document.getElementById('videoremote'+i).parentElement.style.borderRadius = "";
                                                    document.getElementById('videoremote'+i).parentElement.style.boxShadow = "";
                                                    is_talking.set(feeds[i].rfdisplay, false);
                                                    break;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            if(jsep) {
                                Janus.debug("Handling SDP as well...", jsep);
                                sfutest.handleRemoteJsep({ jsep: jsep });
                                // Check if any of the media we wanted to publish has
                                // been rejected (e.g., wrong or unsupported codec)
                                var audio = msg["audio_codec"];
                                if(mystream && mystream.getAudioTracks() && mystream.getAudioTracks().length > 0 && !audio) {
                                    // Audio has been rejected
                                    //toastr.warning("Our audio stream has been rejected, viewers won't hear us");
                                    Janus.warning("Our audio stream has been rejected, viewers won't hear us");
                                }
                                var video = msg["video_codec"];
                                if(mystream && mystream.getVideoTracks() && mystream.getVideoTracks().length > 0 && !video) {
                                    // Video has been rejected
                                    //toastr.warning("Our video stream has been rejected, viewers won't see us");
                                    // Hide the webcam video
                                    //$('#myvideo').hide();
                                    //$('#videolocal').append(
                                    //    '<div class="no-video-container">' +
                                    //        '<i class="fa fa-video-camera fa-5 no-video-icon" style="height: 100%;"></i>' +
                                    //        '<span class="no-video-text" style="font-size: 16px;">Video rejected, no webcam</span>' +
                                    //    '</div>');
                                }
                            }
                        },
                        onlocaltrack: function(track, on) {
                            Janus.debug("Local track " + (on ? "added" : "removed") + ":", track);
                            // We use the track ID as name of the element, but it may contain invalid characters
                            var trackId = track.id.replace(/[{}]/g, "");
                            if(!on) {
                                // Track removed, get rid of the stream and the rendering
                                var stream = localTracks[trackId];
                                if(stream) {
                                    try {
                                        var tracks = stream.getTracks();
                                        for(var i in tracks) {
                                            var mst = tracks[i];
                                            if(mst !== null && mst !== undefined)
                                                mst.stop();
                                        }
                                    } catch(e) {}
                                }
                                if(track.kind === "video") {
                                    $('#myvideo' + trackId).remove();
                                    localVideos--;
                                    if(localVideos === 0) {
                                        // No video, at least for now: show a placeholder
                                        //if($('#videolocal .no-video-container').length === 0) {
                                        //    $('#videolocal').append(
                                        //        '<div class="no-video-container">' +
                                        //            '<i class="fa fa-video-camera fa-5 no-video-icon"></i>' +
                                        //            '<span class="no-video-text">No webcam available</span>' +
                                        //        '</div>');
                                        //}
                                    }
                                }
                                delete localTracks[trackId];
                                return;
                            }
                            // If we're here, a new track was added
                            var stream = localTracks[trackId];
                            if(stream) {
                                // We've been here already
                                return;
                            }
                            //$('#videos').removeClass('hide').show();
                            if(track.kind === "audio") {
                                // We ignore local audio tracks, they'd generate echo anyway
                                if(localVideos === 0) {
                                    // No video, at least for now: show a placeholder
                                    //if($('#videolocal .no-video-container').length === 0) {
                                    //    $('#videolocal').append(
                                    //        '<div class="no-video-container">' +
                                    //            '<i class="fa fa-video-camera fa-5 no-video-icon"></i>' +
                                    //            '<span class="no-video-text">No webcam available</span>' +
                                    //        '</div>');
                                    //}
                                }
                            } else {
                                // New video track: create a stream out of it
                                localVideos++;
                                //$('#videolocal .no-video-container').remove();
                                stream = new MediaStream([track]);
                                localTracks[trackId] = stream;
                                Janus.log("Created local stream:", stream);
                                Janus.log(stream.getTracks());
                                Janus.log(stream.getVideoTracks());
                                $('#videolocal').append('<video class="rounded centered" id="myvideo' + trackId + '" autoplay playsinline muted="muted"/>');
                                Janus.attachMediaStream($('#myvideo' + trackId).get(0), stream);
                                published = true;
                                $('#unpublish').attr('src', camPublished);
                                // set local talking time
                                if(talking_time.has(String(session.actor)))
                                {
                                    let time = talking_time.get(String(session.actor));
                                    let display_time = (time.h < 10 ? "0" + time.h : time.h) + ":" + (time.m < 10 ? "0" + time.m : time.m) + ":" + (time.s < 10 ? "0" + time.s : time.s);
                                    document.getElementById("clocklocal").innerText = display_time;
                                    document.getElementById("clocklocal").textContent = display_time;
                                }
                                // cap default bandwith to 512 kBit/s only
                                //$('#bitrate').val('512').trigger('change');
                                //sfutest.send({ message: { request: "configure", bitrate: 512 }});
                            }
                            if(sfutest.webrtcStuff.pc.iceConnectionState !== "completed" &&
                                    sfutest.webrtcStuff.pc.iceConnectionState !== "connected") {
                                //$("#videolocal").parent().parent().block({
                                //    message: '<b>Publishing...</b>',
                                //    css: {
                                //        border: 'none',
                                //        backgroundColor: 'transparent',
                                //        color: 'white'
                                //    }
                                //});
                            }
                        },
                        onremotetrack: function(track, mid, on) {
                            // The publisher stream is sendonly, we don't expect anything here
                        },
                        oncleanup: function() {
                            Janus.log(" ::: Got a cleanup notification: we are unpublished now :::");
                            mystream = null;
                            delete feedStreams[myid];
                            $('#videolocal').html('');
                            localTracks = {};
                            localVideos = 0;
                        }
                    });
                },
                error: function(error) {
                    Janus.error(error);
                    alert(error, function() {
                        window.location.reload();
                    });
                },
                destroyed: function() {
                    //window.location.reload();
                    console.log("janus destroyed...");
                    // clean up everything janus related
                    Object.entries(localTracks).map(e => {
                        try {
                            var tracks = e[1].getTracks();
                            for(var i in tracks) {
                                var mst = tracks[i];
                                if(mst !== null && mst !== undefined)
                                    mst.stop();
                            }
                        } catch(e) {}
                    })
                    Object.entries(feedStreams).forEach(e => {
                        delete feedStreams[e[0]];
                    })
                    for(var i=1; i<6; i++) {
                        if(feeds[i]) {
                            feeds[i].detach();
                            feeds[i] = null;
                        }
                        if(bitrateTimer[i]) {
                            clearInterval(bitrateTimer[i]);
                            bitrateTimer[i] = null;
                        }
                    }
                    $('#videolocal').empty();
                    $('#videoremote1').empty();
                    $('#videoremote2').empty();
                    $('#videoremote3').empty();
                    $('#videoremote4').empty();
                    $('#videoremote5').empty();
                    myid = null;
                    mystream = null;
                    localTracks = {};
                    localVideos = 0;
                    feeds = [];
                    feedStreams = {};
                    joinedRoom = false
                    onJoinedRoom(joinedRoom);
                }
            });
        }});
        joinedRoom = true;
        onJoinedRoom(joinedRoom);
    },

    toggleMute: function()
    {
        let muted = sfutest.isAudioMuted();
        Janus.log((muted ? "Unmuting" : "Muting") + " local stream...");
        if(muted)
            sfutest.unmuteAudio();
        else
            sfutest.muteAudio();
        muted = sfutest.isAudioMuted();
        $('#mute').attr('src', muted ? micMuted : micUnmuted);
    },
    togglePublish: function()
    {
        if(published)
        {
            unpublishOwnFeed();
        }
        else
        {
            publishOwnFeed(true);
        }
    },
    setBitrate: function()
    {
        let id = $('#bitrate').val();
        let bitrate = parseInt(id) * 1000;
        if(bitrate === 0)
        {
            Janus.log("Not limiting bandwidth via REMB");
        }
        else
        {
            Janus.log("Capping bandwidth to " + bitrate + " via REMB");
        }
        sfutest.send({ message: { request: "configure", bitrate: bitrate }});
    }
}

// Helper to parse query string
function getQueryStringValue(name)
{
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
        results = regex.exec(window.location.search);
    return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
}

// Helper to escape XML tags
function escapeXmlTags(value)
{
    if(value)
    {
        var escapedValue = value.replace(new RegExp('<', 'g'), '&lt');
        escapedValue = escapedValue.replace(new RegExp('>', 'g'), '&gt');
        return escapedValue;
    }
}

function unpublishOwnFeed()
{
    // Unpublish our stream
    //$('#unpublish').attr('disabled', true).unbind('click');
    //document.getElementById('unpublish').setAttribute('disabled', true);
    var unpublish = { request: "unpublish" };
    sfutest.send({ message: unpublish });
}

function publishOwnFeed(useAudio)
{
    // Publish our stream
    //$('#publish').attr('disabled', true).unbind('click');

    // We want sendonly audio and video (uncomment the data track
    // too if you want to publish via datachannels as well)
    let tracks = [];
    if(useAudio)
        tracks.push({ type: 'audio', capture: true, recv: false });
    tracks.push({ type: 'video', capture: true, recv: false, simulcast: false });
    //~ tracks.push({ type: 'data' });

    sfutest.createOffer({
        tracks: tracks,
        customizeSdp: function(jsep) {
            // If DTX is enabled, munge the SDP
            if(doDtx) {
                jsep.sdp = jsep.sdp
                    .replace("useinbandfec=1", "useinbandfec=1;usedtx=1")
            }
        },
        success: function(jsep) {
            Janus.debug("Got publisher SDP!", jsep);
            var publish = { request: "configure", audio: useAudio, video: true };
            // You can force a specific codec to use when publishing by using the
            // audiocodec and videocodec properties, for instance:
            //         publish["audiocodec"] = "opus"
            // to force Opus as the audio codec to use, or:
            //         publish["videocodec"] = "vp9"
            // to force VP9 as the videocodec to use. In both case, though, forcing
            // a codec will only work if: (1) the codec is actually in the SDP (and
            // so the browser supports it), and (2) the codec is in the list of
            // allowed codecs in a room. With respect to the point (2) above,
            // refer to the text in janus.plugin.videoroom.jcfg for more details.
            // We allow people to specify a codec via query string, for demo purposes
            if(acodec)
                publish["audiocodec"] = acodec;
            if(vcodec)
                publish["videocodec"] = vcodec;
            sfutest.send({ message: publish, jsep: jsep });
        },
        error: function(error) {
            Janus.error("WebRTC error:", error);
            if(useAudio) {
                    publishOwnFeed(false);
            } else {
                alert("WebRTC error... " + error.message);
                //$('#publish').removeAttr('disabled').click(function() { publishOwnFeed(true); });
            }
        }
    });
}

function newRemoteFeed(id, display, streams)
{
    // A new feed has been published, create a new plugin handle and attach to it as a subscriber
    var remoteFeed = null;
    if(!streams)
        streams = feedStreams[id];
    janus.attach({
        plugin: "janus.plugin.videoroom",
        opaqueId: opaqueId,
        success: function(pluginHandle) {
            remoteFeed = pluginHandle;
            remoteFeed.remoteTracks = {};
            remoteFeed.remoteVideos = 0;
            remoteFeed.simulcastStarted = false;
            Janus.log("Plugin attached! (" + remoteFeed.getPlugin() + ", id=" + remoteFeed.getId() + ")");
            Janus.log("  -- This is a subscriber");
            // Prepare the streams to subscribe to, as an array: we have the list of
            // streams the feed is publishing, so we can choose what to pick or skip
            var subscription = [];
            for(var i in streams) {
                var stream = streams[i];
                // If the publisher is VP8/VP9 and this is an older Safari, let's avoid video
                if(stream.type === "video" && Janus.webRTCAdapter.browserDetails.browser === "safari" &&
                        (stream.codec === "vp9" || (stream.codec === "vp8" && !Janus.safariVp8))) {
                    //toastr.warning("Publisher is using " + stream.codec.toUpperCase +
                    //    ", but Safari doesn't support it: disabling video stream #" + stream.mindex);
                    continue;
                }
                subscription.push({
                    feed: stream.id,    // This is mandatory
                    mid: stream.mid        // This is optional (all streams, if missing)
                });
                // FIXME Right now, this is always the same feed: in the future, it won't
                remoteFeed.rfid = stream.id;
                remoteFeed.rfdisplay = escapeXmlTags(stream.display);
            }
            // We wait for the plugin to send us an offer
            var subscribe = {
                request: "join",
                room: myRoom,
                ptype: "subscriber",
                streams: subscription,
                use_msid: use_msid,
                private_id: mypvtid
            };
            remoteFeed.send({ message: subscribe });
        },
        error: function(error) {
            Janus.error("  -- Error attaching plugin...", error);
            alert("Error attaching plugin... " + error);
        },
        iceState: function(state) {
            Janus.log("ICE state (feed #" + remoteFeed.rfindex + ") changed to " + state);
        },
        webrtcState: function(on) {
            Janus.log("Janus says this WebRTC PeerConnection (feed #" + remoteFeed.rfindex + ") is " + (on ? "up" : "down") + " now");
        },
        slowLink: function(uplink, lost, mid) {
            Janus.warn("Janus reports problems " + (uplink ? "sending" : "receiving") +
                " packets on mid " + mid + " (" + lost + " lost packets)");
        },
        onmessage: function(msg, jsep) {
            Janus.debug(" ::: Got a message (subscriber) :::", msg);
            var event = msg["videoroom"];
            Janus.debug("Event: " + event);
            if(msg["error"]) {
                alert(msg["error"]);
            } else if(event) {
                if(event === "attached") {
                    // Subscriber created and attached
                    for(var i=1;i<6;i++) {
                        if(!feeds[i]) {
                            feeds[i] = remoteFeed;
                            remoteFeed.rfindex = i;
                            break;
                        }
                    }
                    if(!remoteFeed.spinner) {
                        var target = document.getElementById('videoremote'+remoteFeed.rfindex);
                        //remoteFeed.spinner = new Spinner({top:100}).spin(target);
                    } else {
                        //remoteFeed.spinner.spin();
                    }
                    Janus.log("Successfully attached to feed in room " + msg["room"]);
                    //$('#remote'+remoteFeed.rfindex).removeClass('hide').html(remoteFeed.rfdisplay).show();
                    $('#remote'+remoteFeed.rfindex).html(remoteFeed.rfdisplay).show();
                } else if(event === "event") {
                    // Check if we got a simulcast-related event from this publisher
                    var substream = msg["substream"];
                    var temporal = msg["temporal"];
                    if((substream !== null && substream !== undefined) || (temporal !== null && temporal !== undefined)) {
                        //if(!remoteFeed.simulcastStarted) {
                        //    remoteFeed.simulcastStarted = true;
                        //    // Add some new buttons
                        //    addSimulcastButtons(remoteFeed.rfindex, true);
                        //}
                        //// We just received notice that there's been a switch, update the buttons
                        //updateSimulcastButtons(remoteFeed.rfindex, substream, temporal);
                    }
                } else {
                    // What has just happened?
                }
            }
            if(jsep) {
                Janus.debug("Handling SDP as well...", jsep);
                var stereo = (jsep.sdp.indexOf("stereo=1") !== -1);
                // Answer and attach
                remoteFeed.createAnswer(
                    {
                        jsep: jsep,
                        // We only specify data channels here, as this way in
                        // case they were offered we'll enable them. Since we
                        // don't mention audio or video tracks, we autoaccept them
                        // as recvonly (since we won't capture anything ourselves)
                        tracks: [
                            { type: 'data' }
                        ],
                        customizeSdp: function(jsep) {
                            if(stereo && jsep.sdp.indexOf("stereo=1") == -1) {
                                // Make sure that our offer contains stereo too
                                jsep.sdp = jsep.sdp.replace("useinbandfec=1", "useinbandfec=1;stereo=1");
                            }
                        },
                        success: function(jsep) {
                            Janus.debug("Got SDP!", jsep);
                            var body = { request: "start", room: myRoom };
                            remoteFeed.send({ message: body, jsep: jsep });
                        },
                        error: function(error) {
                            Janus.error("WebRTC error:", error);
                            alert("WebRTC error... " + error.message);
                        }
                    });
            }
        },
        onlocaltrack: function(track, on) {
            // The subscriber stream is recvonly, we don't expect anything here
        },
        onremotetrack: function(track, mid, on) {
            Janus.debug("Remote feed #" + remoteFeed.rfindex + ", remote track (mid=" + mid + ") " + (on ? "added" : "removed") + ":", track);
            if(!on) {
                // Track removed, get rid of the stream and the rendering
                $('#remotevideo'+remoteFeed.rfindex + '-' + mid).remove();
                //let rv = document.getElementById('remotevideo' + remoteFeed.rfindex + '-' + mid); if(rv) rv.remove();
                if(track.kind === "video") {
                    remoteFeed.remoteVideos--;
                    if(remoteFeed.remoteVideos === 0) {
                        // No video, at least for now: show a placeholder
                        if($('#videoremote'+remoteFeed.rfindex + ' .no-video-container').length === 0) {
                            $('#videoremote'+remoteFeed.rfindex).append(
                                '<div class="no-video-container">' +
                                    '<i class="fa fa-video-camera fa-5 no-video-icon"></i>' +
                                    '<span class="no-video-text">No remote video available</span>' +
                                '</div>');
                        }
                        //if(document.querySelector('#videoremote'+remoteFeed.rfindex + ' .no-video-container') === null) {
                        //    document.getElementById('videoremote'+remoteFeed.rfindex).innerHTML += 
                        //        '<div class="no-video-container">' +
                        //            '<i class="fa fa-video-camera fa-5 no-video-icon"></i>' +
                        //            '<span class="no-video-text">No remote video available</span>' +
                        //        '</div>';
                        //}
                    }
                }
                delete remoteFeed.remoteTracks[mid];
                return;
            }
            // If we're here, a new track was added
            if(remoteFeed.spinner) {
                remoteFeed.spinner.stop();
                remoteFeed.spinner = null;
            }
            if($('#remotevideo' + remoteFeed.rfindex + '-' + mid).length > 0)
                return;
            //if(document.getElementById('remotevideo' + remoteFeed.rfindex + '-' + mid))
            //    return;
            if(track.kind === "audio") {
                // New audio track: create a stream out of it, and use a hidden <audio> element
                let stream = new MediaStream([track]);
                remoteFeed.remoteTracks[mid] = stream;
                Janus.log("Created remote audio stream:", stream);
                $('#videoremote'+remoteFeed.rfindex).append('<audio class="hide" id="remotevideo' + remoteFeed.rfindex + '-' + mid + '" autoplay playsinline/>');
                //document.getElementById('videoremote'+remoteFeed.rfindex).innerHTML += '<audio class="hide" id="remotevideo' + remoteFeed.rfindex + '-' + mid + '" autoplay playsinline/>';
                Janus.attachMediaStream($('#remotevideo' + remoteFeed.rfindex + '-' + mid).get(0), stream);
                //Janus.attachMediaStream(document.getElementById('remotevideo' + remoteFeed.rfindex + '-' + mid), stream);
                if(remoteFeed.remoteVideos === 0) {
                    // No video, at least for now: show a placeholder
                    if($('#videoremote'+remoteFeed.rfindex + ' .no-video-container').length === 0) {
                        $('#videoremote'+remoteFeed.rfindex).append(
                            '<div class="no-video-container">' +
                                '<i class="fa fa-video-camera fa-5 no-video-icon"></i>' +
                                '<span class="no-video-text">No remote video available</span>' +
                            '</div>');
                    }
                    //if(document.querySelector('#videoremote'+remoteFeed.rfindex + ' .no-video-container') === null) {
                    //    document.getElementById('videoremote'+remoteFeed.rfindex).innerHTML += 
                    //        '<div class="no-video-container">' +
                    //            '<i class="fa fa-video-camera fa-5 no-video-icon"></i>' +
                    //            '<span class="no-video-text">No remote video available</span>' +
                    //        '</div>';
                    //}
                }
            } else {
                // New video track: create a stream out of it
                remoteFeed.remoteVideos++;
                $('#videoremote'+remoteFeed.rfindex + ' .no-video-container').remove();
                let stream = new MediaStream([track]);
                remoteFeed.remoteTracks[mid] = stream;
                Janus.log("Created remote video stream:", stream);
                $('#videoremote'+remoteFeed.rfindex).append('<video class="rounded centered" id="remotevideo' + remoteFeed.rfindex + '-' + mid + '" autoplay playsinline/>');
                Janus.attachMediaStream($('#remotevideo' + remoteFeed.rfindex + '-' + mid).get(0), stream);
                // set clockremote to talking time of this user
                if(talking_time.has(remoteFeed.rfdisplay))
                {
                    let time = talking_time.get(remoteFeed.rfdisplay);
                    let display_time = (time.h < 10 ? "0" + time.h : time.h) + ":" + (time.m < 10 ? "0" + time.m : time.m) + ":" + (time.s < 10 ? "0" + time.s : time.s);
                    document.getElementById("clockremote" + remoteFeed.rfindex).innerText = display_time;
                    document.getElementById("clockremote" + remoteFeed.rfindex).textContent = display_time;
                }
                // Note: we'll need this for additional videos too
                if(!bitrateTimer[remoteFeed.rfindex]) {
                    //$('#curbitrate'+remoteFeed.rfindex).removeClass('hide').show();
                    bitrateTimer[remoteFeed.rfindex] = setInterval(function() {
                        if(!$("#videoremote" + remoteFeed.rfindex + ' video').get(0))
                            return;
                        // Display updated bitrate, if supported
                        var bitrate = remoteFeed.getBitrate();
                        $('#curbitrate'+remoteFeed.rfindex).text(bitrate);
                        // Check if the resolution changed too
                        var width = $("#videoremote" + remoteFeed.rfindex + ' video').get(0).videoWidth;
                        var height = $("#videoremote" + remoteFeed.rfindex + ' video').get(0).videoHeight;
                        if(width > 0 && height > 0)
                            $('#curres'+remoteFeed.rfindex).text(width+'x'+height+'@');
                    }, 1000);
                }
            }
        },
        oncleanup: function() {
            Janus.log(" ::: Got a cleanup notification (remote feed " + id + ") :::");
            if(remoteFeed.spinner)
                remoteFeed.spinner.stop();
            remoteFeed.spinner = null;
            $('#remotevideo'+remoteFeed.rfindex).remove();
            //$('#waitingvideo'+remoteFeed.rfindex).remove();
            //$('#novideo'+remoteFeed.rfindex).remove();
            //$('#curbitrate'+remoteFeed.rfindex).remove();
            //$('#curres'+remoteFeed.rfindex).remove();
            $('#curbitrate'+remoteFeed.rfindex).text('');
            $('#curres'+remoteFeed.rfindex).text('');
            if(bitrateTimer[remoteFeed.rfindex])
                clearInterval(bitrateTimer[remoteFeed.rfindex]);
            bitrateTimer[remoteFeed.rfindex] = null;
            remoteFeed.simulcastStarted = false;
            //$('#simulcast'+remoteFeed.rfindex).remove();
            remoteFeed.remoteTracks = {};
            remoteFeed.remoteVideos = 0;
        }
    });
}